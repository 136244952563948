import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Typography, List, Skeleton, Badge, Tooltip } from "antd";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import K from "utilities/constants";
import { convertToSlug, replaceWordWithBold } from "utilities/generalUtility";
import Highlighter from "react-highlight-words";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCurrentDateAndTime } from "utilities/dateUtility";
import PropTypes from "prop-types";

const { Text, Title } = Typography;

JobDetailsModal.propTypes = {
  comments: PropTypes.array,
  errors: PropTypes.array,
  clientDetails: PropTypes.object,
};

export default function JobDetailsModal({ comments, errors, clientDetails: { company } }) {
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
      showSizeChanger: false,
      // pageSizeOptions: ["5", "10", "20", "50"],
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const filterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }, dataIndex) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            className="gradient-btn"
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          >
            Search
          </Button>
          <Button
            className="cancel-btn"
            size="small"
            style={{ width: 90 }}
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""], confirm, dataIndex);
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  };

  const filterIconComponent = (filtered) => {
    return <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />;
  };

  const getColumnSearchProps = (dataIndex) => ({
    // sorter: (a, b) => stringSorting(a, b, dataIndex),
    filterIcon: (filtered) => filterIconComponent(filtered),
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) setTimeout(() => searchInput.current?.select(), 100);
    },
    filterDropdown: (data) => filterDropdownComponent(data, dataIndex),
    render: (text) => {
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
      } else {
        return text || <Text type="secondary">{K.NullReplacement}</Text>;
      }
    },
  });

  const columns = [
    {
      title: "E-File Column Name",
      dataIndex: "eFileColumnName",
      key: "eFileColumnName",
      width: "10%",
      ...getColumnSearchProps("eFileColumnName"),
    },
    {
      title: "Input File Prefix",
      dataIndex: "fileNamePrefix",
      key: "fileName",
      width: "5%",
      ...getColumnSearchProps("fileNamePrefix"),
    },
    {
      title: "File Column Name",
      dataIndex: "fileColumnName",
      key: "fileColumnName",
      width: "10%",
      ...getColumnSearchProps("fileColumnName"),
    },
    {
      title: "File Row Number",
      dataIndex: "fileRowNumber",
      key: "fileRowNumber",
      width: "5%",
      ...getColumnSearchProps("fileRowNumber"),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "15%",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const csvFileNameGenerator = (fileName) => {
    const file = convertToSlug(company) + fileName + getCurrentDateAndTime();
    const replaceFile = file
      .replaceAll(".", "")
      .replaceAll("#", "")
      .replaceAll("$", "")
      .replaceAll("[", "")
      .replaceAll("]", "")
      .replaceAll("/", "")
      .replaceAll(":", "")
      .replaceAll(";", "")
      .replaceAll("=", "")
      .replaceAll("?", "")
      .replaceAll("@", "")
      .replaceAll("&", "");
    return replaceFile;
  };

  return (
    <>
      {comments?.length > 0 && (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Title level={5} className="pr-1">
                Comments{" "}
              </Title>
              <Badge style={{ paddingTop: "1px" }} count={comments.length} overflowCount={999} />
            </div>
            <CSVLink
              data={comments.map((item) => ({
                comments: item,
              }))}
              headers={[{ label: "Comments", key: "comments" }]}
              filename={csvFileNameGenerator("-comments-")}
            >
              <Tooltip title="Download as CSV">{<DownloadOutlined style={{ fontSize: "20px", color: "#5468ff" }} />}</Tooltip>
            </CSVLink>
          </div>

          <div
            id="scrollableDiv"
            style={{
              maxHeight: 150,
              overflow: "auto",
              padding: "0 16px",
              border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            <InfiniteScroll
              dataLength={comments.length}
              next={""}
              hasMore={false}
              loader={
                <Skeleton
                  paragraph={{
                    rows: 1,
                  }}
                />
              }
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={comments}
                renderItem={(item, key) => (
                  <List.Item key={key} style={{ height: 38 }}>
                    <List.Item.Meta
                      style={{ height: 14 }}
                      title={
                        <div className="d-flex">
                          <span className="mr-1">{key + 1}.</span>
                          <span>{replaceWordWithBold(item)}</span>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </>
      )}
      <br />
      {errors?.length > 0 && (
        <>
          <div className="d-flex justify-content-between">
            <Title level={5}>File Errors</Title>
            <CSVLink
              data={errors.map((err) => ({
                eFileColumn: err.eFileColumn?.name,
                fileNamePrefix: err.fileConfigurations?.fileNamePrefix,
                fileColumnName: err.fileColumnName,
                fileRowNumber: err.fileRowNumber,
                message: err.message,
              }))}
              headers={[
                { label: "E-File Column Name", key: "eFileColumn" },
                { label: "Input File Prefix", key: "fileNamePrefix" },
                { label: "File Column Name", key: "fileColumnName" },
                { label: "File Row Number", key: "fileRowNumber" },
                { label: "Message", key: "message" },
              ]}
              filename={csvFileNameGenerator("-file_errors-")}
            >
              <Tooltip title="Download as CSV">{<DownloadOutlined style={{ fontSize: "20px", color: "#5468ff" }} />}</Tooltip>
            </CSVLink>
          </div>
          <Table
            size="small"
            pagination={tableParams.pagination}
            className="common-table"
            columns={columns}
            dataSource={errors?.map((val, key) => ({ key: key, ...val }))}
            scroll={{ x: 1300 }}
            onChange={handleTableChange}
          />
        </>
      )}
    </>
  );
}
