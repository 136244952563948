import React from "react";
import { Layout } from "antd";
import moment from "moment";

export default function Footer() {
  const { Footer } = Layout;
  return (
    <Footer style={{ textAlign: "center" }}>
      One Digital © {moment().year()} 
    </Footer>
  );
}
