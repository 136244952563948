import React, { useEffect } from "react";
import { Form, Input, Button, Checkbox, Card, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Auth from "models/auth/auth";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { deleteQueryParam, setFieldErrorsFromServer } from "utilities/generalUtility";
import logo from "../../assets/images/OneDigital.png";
import qs from "qs";
import md5 from "md5";

export default function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const paramJson = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const encryptedPass = md5(values.password);
      await dispatch(Auth.loginCall(values.email, encryptedPass, values.remember));
      const { from } = location.state || { from: { path: "/" } };
      history.replace(from);
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="login-container">
      <div className="lc-logo">
        <img src={logo} alt="OneDigital Logo" />
      </div>
      <Card bordered={false} className="login-card">
        <h4>Login to your account</h4>
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
            <Input
              type="email"
              placeholder="Email"
              size="large"
              maxLength={100}
              prefix={<UserOutlined className="site-form-item-icon text-primary" />}
            />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
            <Input.Password
              placeholder="Password"
              size="large"
              maxLength={100}
              prefix={<LockOutlined className="site-form-item-icon text-primary" />}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            {/* <Link to="/forgot-password" className="float-right">
                Forgot password
              </Link> */}
          </Form.Item>
          <Form.Item className="mb-0">
            <Button className="gradient-btn" block size="large" type="primary" htmlType="submit">
              Log In
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
