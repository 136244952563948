import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authDetails: ""
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    saveAuthenticatedUserData: (state, action) => {
      state.authDetails = action.payload;
    }
  },
});

export const { saveAuthenticatedUserData } = authSlice.actions;

export default authSlice.reducer;