import { InboxOutlined } from "@ant-design/icons";
import { Badge, Button, List, Modal, Skeleton, Typography, message } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";
import { createMappingsPayload, fileSheetReader, validateMappingsExcelData } from "utilities/generalUtility";
import PropTypes from "prop-types";

const { Title } = Typography;

UploadEFileMappingsModal.propTypes = {
  allForms: PropTypes.object,
  fileConfiguration: PropTypes.object,
  clientId: PropTypes.number,
  eFileColumns: PropTypes.array,
  setMappings: PropTypes.func,
  setDefaultOriginalMappingValues: PropTypes.func,
};

export default function UploadEFileMappingsModal({
  allForms,
  fileConfiguration,
  clientId,
  eFileColumns,
  setMappings,
  setDefaultOriginalMappingValues,
}) {
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState({ errors: [], payload: null });
  const [showUploadList, setShowUploadList] = useState(false);
  const [loader, setLoader] = useState(false);

  const uploadProps = {
    maxCount: 1,
    multiple: false,
    action: "",
    showUploadList: showUploadList && {
      showRemoveIcon: false,
    },
    headers: { authorization: "authorization-text" },
    beforeUpload: (file) => {
      readUploadedFile(file);

      // To prevent send network call
      return false;
    },
  };

  const readUploadedFile = async (file) => {
    try {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setUploadedFileData({ errors: [`${file.name} is not an XLSX file`], payload: null });
        message.error(`${file.name} is not an XLSX file`);
        setShowUploadList(false);
        return false;
      }
      // Read the file
      const res = await trackPromise(fileSheetReader(file, "Mappings"));

      // Validate data
      const errors = validateMappingsExcelData(res);

      // Create the payload object
      const payload = createMappingsPayload(res, fileConfiguration?.files, clientId);

      // Update the error and payload state
      if (errors.length === 0) {
        setShowUploadList(true);
        message.success(`${file.name} file uploaded successfully.`);
        setUploadedFileData({ errors, payload });
      } else {
        setShowUploadList(false);
        message.error(`${file.name} file upload failed.`);
        setUploadedFileData({ errors, payload: null });
      }
    } catch (error) {
      setUploadedFileData({ errors: [error], payload: null });
      message.error(error);
    }
  };

  const populateUploadedMappingsFile = () => {
    setTimeout(() => {
      // Set all forms empty
      eFileColumns?.forEach((file, index) => {
        allForms?.current?.["form" + file?.id]?.setFieldsValue({
          // file: null,
          column: null,
          valueMappings: [],
        });
      });

      // Set new form data
      const data = uploadedFileData?.payload;
      setMappings(data);
      setDefaultOriginalMappingValues(data);
      setUploadModal(false);
      setLoader(false);
    }, 100);
  };

  useEffect(() => {
    if (loader) {
      populateUploadedMappingsFile();
    }
  }, [loader]);

  return (
    <div>
      <Button type="primary" className="upload-btn" onClick={() => setUploadModal(true)}>
        Upload Mappings
      </Button>

      {/* Upload Mappings File */}
      <Modal
        title="Upload Mappings"
        open={uploadModal}
        onOk={() => populateUploadedMappingsFile()}
        onCancel={() => setUploadModal(false)}
        width="50%"
        footer={[
          <Button key="back" onClick={() => setUploadModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setLoader(true);
            }}
            disabled={uploadedFileData?.errors?.length !== 0 || uploadedFileData?.payload === null || loader}
          >
            {loader ? "Populating..." : "Confirm"}
          </Button>,
        ]}
      >
        {uploadedFileData?.errors?.length > 0 && (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Title level={5} className="pr-1">
                  Errors{" "}
                </Title>
                <Badge style={{ paddingTop: "1px" }} count={uploadedFileData?.errors?.length} overflowCount={999} />
              </div>
            </div>

            <div
              id="scrollableDiv"
              style={{
                maxHeight: 150,
                overflow: "auto",
                padding: "0 0",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <InfiniteScroll
                dataLength={uploadedFileData?.errors.length}
                next={""}
                hasMore={false}
                loader={<Skeleton paragraph={{ rows: 1 }} />}
                scrollableTarget="scrollableDiv"
              >
                <List
                  size="small"
                  dataSource={uploadedFileData?.errors}
                  renderItem={(item, key) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <div className="d-flex">
                            <span className="mr-1">{key + 1}.</span>
                            <span>{item}</span>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
            <br />
          </>
        )}

        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload (Only 1 file is allowed)</p>
          <p className="ant-upload-hint">Uploading the file will overwrite the existing data</p>
        </Dragger>
      </Modal>
    </div>
  );
}
