import { Button, Form, message, Upload } from "antd";
import { useState } from "react";
import { fileReader, handleError } from "utilities/generalUtility";
import Client from "models/client/client";

export default function EeidMappings({ id }) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [data, setData] = useState(null);

  const onFinish = async () => {
    if (data === null) {
      message.error("File must be uplaoded!");
      return;
    }
    try {
      let arr = data.filter((d) => d?.length !== 0);
      arr.shift(); //remove first(header row) index
      let mappings = arr.map((value) => ({
        originalId: value[0],
        incomingId: value[1],
        clientId: id,
      }));
      await Client.createEeidMappings({ mappings });
      setUploadedFile(null);
      setData(null);
      message.success("EEID Mappings created successfully!");
    } catch (error) {
      handleError(error);
    }
  };

  const readFiles = async (file) => {
    try {
      let { data } = await fileReader(file);
      setData(data);
    } catch (error) {
      message.error(error);
    }
  };

  const props = {
    maxCount: 1,
    multiple: false,
    action: "",
    showUploadList: false,
    headers: { authorization: "authorization-text" },
    beforeUpload: (file) => {
      if (file.type !== "text/csv") {
        message.error(`${file.name} is not a csv file`);
        return Upload.LIST_IGNORE;
      }
      readFiles(file);
      setUploadedFile(file);
    },
  };

  return (
    <>
      <Form name="uploads" encType="multipart/form-data" className="eeid-mappings-form" onFinish={onFinish}>
        <div className="upload-btn-with-label">
          <Form.Item>
            <Upload {...props} name="uploadedFile">
              <Button type="primary" className="upload-btn">
                Upload EEID Mappings CSV
              </Button>
            </Upload>
          </Form.Item>
          <label className="upload-btn-label">
            <span className="upload-btn-span">{uploadedFile?.name}</span>
          </label>
        </div>

        <Form.Item className="client-details-btn mb-0">
          <Button className="gradient-btn" size="large" type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
