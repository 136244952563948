import { SearchOutlined } from "@ant-design/icons";
import { Card, Button, Input, Space, Table, Modal, Row, Col, Form, message, InputNumber, Select, Popconfirm, Tag } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { storeClients, storeFileConfiguration } from "redux/client/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import Client from "models/client/client";
import { stringSorting } from "utilities/tableUtility";
import Highlighter from "react-highlight-words";
import { maxLengthValidation, noSpecialCharactersRule, noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import K from "utilities/constants";

export default function Clients() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const clients = useSelector((state) => state.clients);
  const history = useHistory();

  const fetchClients = useCallback(async () => {
    try {
      const data = await Client.fetchClients();
      await dispatch(storeClients(data));
      // Reset file configuration, so the previous updated file configuration doesn't effect the other clients
      await dispatch(storeFileConfiguration(null));
      form.resetFields();
    } catch (error) {
      console.error(error);
    }
  }, [form, dispatch]);

  useEffect(() => {
    if (clients.length !== 0) fetchClients();
  }, [fetchClients]);

  const onFormFinish = async (values) => {
    try {
      const data = await Client.createNewClient(values);
      message.success(data?.message);
      fetchClients();
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onFormFinishFailed = (err) => {
    console.log("Create New Client Form failed to submit", err);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const clearFormFields = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const deleteClient = async (id) => {
    await Client.deleteClient(id);
    message.success("Client has been deleted successfully!");
    fetchClients();
  };

  const filterIconComponent = (filtered) => {
    return <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />;
  };

  const filterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }, dataIndex) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            className="gradient-btn"
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            className="cancel-btn"
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterIcon: (filtered) => filterIconComponent(filtered),
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) setTimeout(() => searchInput.current?.select(), 100);
    },
    filterDropdown: (data) => filterDropdownComponent(data, dataIndex),
    render: (text, record) => {
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
      } else {
        return text;
      }
    },
  });

  const columns = [
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      width: "19%",
      sorter: (a, b) => stringSorting(a, b, "company"),
      ...getColumnSearchProps("company"),
    },
    // {
    //   title: "Phone Number",
    //   dataIndex: "phone",
    //   key: "phone",
    //   width: "19%",
    //   ...getColumnSearchProps("phone"),
    // },
    {
      title: "SFTP Creds (User Name)",
      dataIndex: "sftpUsername",
      key: "sftpUsername",
      width: "19%",
      ...getColumnSearchProps("sftpUsername"),
    },
    {
      title: "Input Files Count",
      dataIndex: "inputFilesCount",
      key: "inputFilesCount",
      width: "19%",
      sorter: (a, b) => stringSorting(a, b, "inputFilesCount"),
      ...getColumnSearchProps("inputFilesCount"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      width: "20%",
      ...getColumnSearchProps("status"),
      render: (_, record) => {
        return <Tag color={K.Status[record.status]}>{record.status}</Tag>;
      },
    },
    {
      title: "",
      dataIndex: "details",
      key: "details",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          {/* <Button type="link" className="p-0">
            Edit
          </Button> */}
          <Popconfirm
            title="Are you sure to delete this client?"
            onConfirm={() => deleteClient(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" className="p-0">
              Delete
            </Button>
          </Popconfirm>
          {/* <Link to={`/clients/details/${record.id}`}>Details</Link> */}
        </Space>
      ),
    },
  ];

  const handleRowClick = (record, e) => {
    history.push(`/clients/details/${record.id}`);
  };

  return (
    <>
      <Card
        size="large"
        title="Clients"
        className="create-client-wrap"
        extra={
          <Button size="large" onClick={showModal} className="gradient-btn create-client-btn">
            Create New Client
          </Button>
        }
      >
        <Table
          size="small"
          className="common-table"
          rowClassName="common-table-row"
          columns={columns}
          scroll={{ x: 500 }}
          dataSource={clients.clients.map((client, key) => ({ key, ...client }))}
          onRow={(record) => ({ onClick: () => handleRowClick(record) })}
          // sortDirections={["ascend", "descend"]}
        />
      </Card>
      <Modal
        title="Create Client"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="create-client-modal"
        width={800}
        centered
        footer={
          <div className="modal-footer">
            <Button type="link" className="delete-btn" icon={<i className="icon-delete"></i>} onClick={clearFormFields}>
              Clear
            </Button>
            <div>
              <Button key="back" onClick={handleCancel} className="cancel-btn" size="large">
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={handleOk} className="add-btn" size="large">
                Add
              </Button>
            </div>
          </div>
        }
        closeIcon={<Button className="closeBtn" type="link" icon={<i className="icon-closeable"></i>} />}
      >
        <Form layout="vertical" form={form} onFinish={onFormFinish} onFinishFailed={onFormFinishFailed} autoComplete="off">
          <Row gutter={20}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Company Name:"
                name="company"
                rules={[
                  { required: true, message: "Please enter your company name!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "Company name") },
                ]}
              >
                <Input placeholder="Enter your company name" />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Phone Number:"
                name="phone"
                rules={[
                  { required: true, message: "Please enter your phone number!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "Phone number", "Digits") },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Employer Reference Code:"
                name="employerReferenceCode"
                rules={[
                  { required: true, message: "Please enter your employer reference code!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "Employer Reference Code") },
                ]}
              >
                <Input placeholder="Enter your employer reference code" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Start Day Of Week:"
                name="startDayOfWeek"
                rules={[{ required: true, message: "Please select your start day of the week!" }]}
              >
                <Select placeholder="Select start day of the week">
                  <Select.Option value="1">Monday</Select.Option>
                  <Select.Option value="2">Tuesday</Select.Option>
                  <Select.Option value="3">Wednesday</Select.Option>
                  <Select.Option value="4">Thursday</Select.Option>
                  <Select.Option value="5">Friday</Select.Option>
                  <Select.Option value="6">Saturday</Select.Option>
                  <Select.Option value="0">Sunday</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="STD Hours:"
                name="standardHours"
                rules={[
                  { required: true, message: "Please enter your standard hours!" },
                  {
                    validator: async (_, number) => {
                      maxLengthValidation(number, 100, "Standard hours", "Digits");
                      if (number < 0 || number > 80) {
                        return Promise.reject(new Error("Standard hours should between 0-80!"));
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  className="input-number"
                  placeholder="Enter your standard hours"
                  type="number"
                  formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
                  parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="50 in 75:"
                name="fiftyInSeventyFive"
                rules={[{ required: true, message: "Please select your 50 in 75!" }]}
              >
                <Select placeholder="Select your 50 in 75">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="HR Team Email: "
                name="hrTeamEmail"
                rules={[
                  { required: true, message: "Please enter your hr team email!" },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "HR team email") },
                ]}
              >
                <Input placeholder="Enter your hr team email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="File Disposition:"
                name="fileDisposition"
                initialValue={"DONT"}
                rules={[{ required: true, message: "Please select your disposition!" }]}
              >
                <Select placeholder="Select your file disposition">
                  <Select.Option value={"DONT"}>Don't Send</Select.Option>
                  <Select.Option value={"PROD"}>Send to Production</Select.Option>
                  <Select.Option value={"STAGE"}>Send to Staging</Select.Option>
                  <Select.Option value={"BOTH"}>Send to Both</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Status:"
                name="status"
                initialValue={"ACTIVE"}
                rules={[{ required: true, message: "Please select your status!" }]}
              >
                <Select placeholder="Select your status">
                  <Select.Option value={"ACTIVE"}>Active</Select.Option>
                  <Select.Option value={"IMPLEMENTATION"}>Implementation</Select.Option>
                  <Select.Option value={"SUSPENDED"}>Suspended</Select.Option>
                  <Select.Option value={"TERMINATED"}>Terminated</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label="Percentage of Change:"
                name="eFileChangePercentage"
                rules={[
                  { required: true, message: "Please enter your percentage of change!" },
                  {
                    validator: async (_, number) => {
                      maxLengthValidation(number, 100, "Percentage of change", "Digits");
                      if (number < 0 || number > 100) {
                        return Promise.reject(new Error("Percentage of change should between 0-100!"));
                      }
                    },
                  },
                ]}
              >
                <InputNumber className="input-number" placeholder="Enter your percentage of change" type="number" />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}></Col>
            {/* <Col xs={24} sm={12} lg={12}>
              <div style={{ fontSize: "14px", fontWeight: "700", color: "#525355", marginBottom: "20px" }}>
                Preprocessor
              </div>
            </Col>
            <Col xs={24} sm={12} lg={12}></Col> */}
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Preprocessor SFTP Username:"
                name="sftpUsername"
                rules={[
                  noTrailingSpaceAllowedRule(),
                  noSpecialCharactersRule(),
                  { required: true, message: "Please enter your preprocessor sftp username!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "Preprocessor SFTP Username") },
                ]}
              >
                <Input placeholder="Enter your preprocessor sftp username" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Preprocessor SFTP Password: "
                name="sftpPassword"
                rules={[
                  { required: true, message: "Please enter your preprocessor sftp password!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "Preprocessor SFTP Password") },
                ]}
              >
                <Input.Password placeholder="Enter your preprocessor sftp password" />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} lg={12}>
              <div style={{ fontSize: "14px", fontWeight: "700", color: "#525355", marginBottom: "20px" }}>
                AbsenceTracker
              </div>
            </Col>
            <Col xs={24} sm={12} lg={12}></Col> */}
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="AT Username Stage:"
                name="atUsernameStage"
                rules={[
                  noTrailingSpaceAllowedRule(),
                  noSpecialCharactersRule(),
                  { required: true, message: "Please enter your at-username stage!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "AT Username Stage") },
                ]}
              >
                <Input placeholder="Enter your at-username stage" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="AT Password Stage: "
                name="atPasswordStage"
                rules={[
                  { required: true, message: "Please enter your at-password stage!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "AT Password Stage") },
                ]}
              >
                <Input.Password placeholder="Enter your at-password stage" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="AT Username Prod:"
                name="atUsernameProd"
                rules={[
                  noTrailingSpaceAllowedRule(),
                  noSpecialCharactersRule(),
                  { required: true, message: "Please enter your at-username prod!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "AT Username Prod") },
                ]}
              >
                <Input placeholder="Enter your at-username prod" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="AT Password Prod: "
                name="atPasswordProd"
                rules={[
                  { required: true, message: "Please enter your at-password prod!" },
                  { validator: async (_, value) => maxLengthValidation(value, 100, "AT Password Prod") },
                ]}
              >
                <Input.Password placeholder="Enter your at-password prod" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
