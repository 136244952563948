import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import logo from "../assets/images/OneDigital.png";
import Auth from "models/auth/auth";

export default function Header() {
  const { Header } = Layout;
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(null);

  const checkPath = (link) => {
    if (location.pathname === "/" && link === "/clients") return "active";
    else if (location.pathname.includes(link)) return "active";
    else return "not-active";
  };

  useEffect(() => {
    setUserDetails(Auth.getUserObjectFromCookies());
  }, []);

  const menu = (
    <Menu className="dropdown-menu">
      {/* <Menu.Item className="menu-item active" key="0">
        <Link to="/profile-management">Profile Seting</Link>
      </Menu.Item> */}
      <Menu.Item className="menu-item logout-menu" key="1">
        <Link to="#" onClick={() => Auth.logoutCall()}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const navlinks = [
    {
      link: "/clients",
      name: "Clients",
    },
    {
      link: "/users",
      name: "Users",
    },
  ];

  return (
    <Header className="app-header-wrap">
      <div className="app-logo-wrap">
        <Link to="/">
          <img className="app-logo" alt="One Digital" src={logo} />
        </Link>
      </div>
      <div className="app-header-right">
        <nav className="app-navbar">
          <ul>
            {navlinks.map((nav, index) => (
              <li key={index + 1}>
                <Link to={nav.link} className={checkPath(nav.link)}>
                  {nav.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Dropdown className="user-profile-wrap" overlay={menu} trigger={["click"]}>
          <div className="ant-dropdown-link" style={{ cursor: "pointer" }}>
            <Avatar icon={<UserOutlined />} />
            <span className="user-name">{userDetails?.name}</span>
            <i className="icon-downarrow"></i>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
}
