import { Badge, Button, Modal, Radio, Table, Tag, Typography } from "antd";
import Client from "models/client/client";
import React, { useState, useEffect } from "react";
import { getDateAndTime } from "utilities/dateUtility";
import JobDetailsModal from "./jobDetailsModal";
import K from "utilities/constants";
import PropTypes from "prop-types";

const { Text } = Typography;

Jobs.propTypes = {
  clientDetails: PropTypes.object,
  id: PropTypes.number,
};

export default function Jobs({ id, clientDetails }) {
  const [selectedTab, setSelectedTab] = useState("eFileJobs");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobDetails, setJobDetails] = useState(null);
  const [jobsStats, setJobsStats] = useState({ total: 0, completed: 0, warning: 0, failed: 0 });
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10, total: 0 });

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const modifyJobsPayload = (result) => {
    return result.map((job, key) => {
      let sourceFilesWithBadge = null;
      let resultEfilesWithBadge = null;
      let sourceFiles = null;
      let resultEfiles = null;

      // Concat source files and its records
      job.inputFilesRecords?.forEach((inputFile) => {
        sourceFilesWithBadge = (
          <p>
            {sourceFilesWithBadge} {inputFile?.fileName?.replace(".csv", "")}{" "}
            <Badge color="#7B3f00" count={inputFile?.records} overflowCount={9999999}></Badge>
          </p>
        );
        sourceFiles = `${sourceFiles ?? ""} ${inputFile?.fileName?.replace(".csv", "")} (${inputFile?.records})`;
      });
      sourceFilesWithBadge = sourceFilesWithBadge ?? <Text type="secondary">{K.NullReplacement}</Text>;

      // Concat result efile with its records
      resultEfilesWithBadge = job.outputFileName ? (
        <p>
          {job.outputFileName?.replace(".csv", "")}{" "}
          <Badge color="#7B3f00" count={job.outputFileRecords} overflowCount={9999999}></Badge>
        </p>
      ) : (
        <Text type="secondary">{K.NullReplacement}</Text>
      );
      resultEfiles = `${resultEfiles ?? ""} ${job.outputFileName?.replace(".csv", "")} (${job.outputFileRecords})`;

      return {
        key,
        jobId: job.id,
        sourcefiles: job.inputFileNames ? sourceFiles : <Text type="secondary">No File</Text>,
        sourceFilesWithBadge,
        resulteFile: job.outputFileName ? resultEfiles : <Text type="secondary">No File</Text>,
        resultEfilesWithBadge,
        status: job.status,
        datetime: getDateAndTime(job.createdAt),
        comments: job?.eFileJobComments?.map((data) => data?.comment) || [],
        eFileJobErrors: job?.eFileJobErrors || [],
      };
    });
  };

  const getJobs = async (pagination) => {
    try {
      const payload = { page: pagination.page };

      let result = null;
      if (selectedTab === "eFileJobs") result = await Client.getEFileJobs(id, payload);
      else if (selectedTab === "fFileJobs") result = await Client.getFFileJobs(id, payload);
      else result = await Client.getOFileJobs(id, payload);

      const modifiedResult = modifyJobsPayload(result?.fetchedJobs);
      console.log({ modifiedResult });
      if (result?.length !== 0) setJobs(modifiedResult);

      setPagination({ ...pagination, total: result?.pagination?.totalCount });
    } catch (error) {
      console.error(error);
    }
  };

  const getJobsStats = async () => {
    try {
      let result = selectedTab === "eFileJobs" ? await Client.getEFileJobsStats(id) : await Client.getFFileJobsStats(id);
      setJobsStats(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // NOSONAR
    // if (jobs.length === 0) {
    setPagination({ page: 1, pageSize: 10, total: 0 });
    getJobs(pagination);
    getJobsStats();
    // }
  }, [selectedTab]);

  const handleTableChange = (pagination) => {
    getJobs(pagination);
  };

  // NOSONAR
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };

  // NOSONAR
  // const handleReset = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  const setCommentsAndErrors = (record) => {
    let errors = record?.eFileJobErrors?.map((element) => {
      return {
        ...element,
        eFileColumnName: element.eFileColumn.name,
        fileNamePrefix: element.fileConfigurations?.fileNamePrefix,
        fileRowNumber: +element.fileRowNumber,
      };
    });
    setJobDetails({
      comments: record?.comments,
      errors: errors || [],
    });
  };

  // NOSONAR
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
  //   onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) setTimeout(() => searchInput.current?.select(), 100);
  //   },
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: "block" }}
  //       />
  //       <Space>
  //         <Button
  //           className="gradient-btn"
  //           type="primary"
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 90 }}
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           className="cancel-btn"
  //           size="small"
  //           style={{ width: 90 }}
  //           onClick={() => {
  //             clearFilters && handleReset(clearFilters);
  //             handleSearch([""], confirm, dataIndex);
  //           }}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   render: (text, record) => {
  //     if (searchedColumn === dataIndex) {
  //       return (
  //         <Highlighter
  //           highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
  //           searchWords={[searchText]}
  //           autoEscape
  //           textToHighlight={text ? text.toString() : ""}
  //         />
  //       );
  //     } else {
  //       return text;
  //     }
  //   },
  // });

  const columns = [
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
      width: "15%",
      // sorter: (a, b) => numberSorting(a, b, "jobId"),
      // ...getColumnSearchProps("jobId"),
    },
    {
      title: "Source files (Count)",
      dataIndex: "sourcefiles",
      key: "sourcefiles",
      width: "25%",
      // ...getColumnSearchProps("sourcefiles"),
      render: (_, record) => {
        return record?.sourceFilesWithBadge;
      },
    },
    {
      title: `Result ${selectedTab.split("Jobs")[0]} (Count)`,
      dataIndex: "resulteFile",
      key: "resulteFile",
      width: "25%",
      // ...getColumnSearchProps("resulteFile"),
      render: (_, record) => {
        return record?.resultEfilesWithBadge;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      width: "15%",
      // ...getColumnSearchProps("status"),
      render: (_, record) => {
        return <Tag color={K.Status[record.status]}>{record.status}</Tag>;
      },
    },
    {
      title: "Date & Time",
      dataIndex: "datetime",
      key: "datetime",
      width: "20%",
      // ...getColumnSearchProps("datetime"),
    },
    {
      title: "",
      dataIndex: "details",
      key: "details",
      width: "5%",
      align: "center",
      render: (_, record) => {
        if (record.status === "FAILED" || record.status === "WARNING" || record.eFileJobErrors.length > 0) {
          return (
            <Button type="link" onClick={() => setCommentsAndErrors(record)}>
              Details
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (jobDetails !== null) showModal();
  }, [jobDetails]);

  return (
    <>
      <Radio.Group
        value={selectedTab}
        onChange={(selectedTab) => setSelectedTab(selectedTab.target.value)}
        style={{ marginBottom: 16, display: "flex", justifyContent: "end" }}
        buttonStyle="solid"
        optionType="button"
      >
        <Radio.Button value="eFileJobs">E-File Jobs</Radio.Button>
        <Radio.Button value="fFileJobs">F-File Jobs</Radio.Button>
        <Radio.Button value="oFileJobs">O-File Jobs</Radio.Button>
      </Radio.Group>

      <Modal
        title={
          <Text strong style={{ fontSize: 19 }}>
            Job Details
          </Text>
        }
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        width={jobDetails?.errors.length > 0 ? "80%" : "50%"}
        onCancel={handleCancel}
      >
        <JobDetailsModal comments={jobDetails?.comments} errors={jobDetails?.errors} clientDetails={clientDetails} />
      </Modal>
      <div className="mb-2 job-tags">
        <Tag color="#108ee9">Total: {jobsStats.total}</Tag>
        <Tag color="#5fb939">Completed: {jobsStats.completed}</Tag>
        <Tag color="#e59d0f">Warning: {jobsStats.warning}</Tag>
        <Tag color="#d8313e">Failed: {jobsStats.failed}</Tag>
      </div>
      <div>
        <Table
          size="small"
          className="common-table"
          columns={columns}
          dataSource={jobs}
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false,
            onChange: (page, pageSize) => handleTableChange({ page, pageSize }),
          }}
          scroll={{ x: 500 }}
        />
      </div>
    </>
  );
}
