import { Card } from "antd";
import Client from "models/client/client";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ClientDetails from "../clientDetails/components/clientDetails";
import FileConfiguration from "../clientDetails/components/fileConfiguration";
import Jobs from "../clientDetails/components/jobs";
import EFileMappings from "./components/eFileMappings/eFileMappings";
import FFileMappings from "./components/fFileMappings/fFileMappings";
import OFileMappings from "./components/oFileMappings/oFileMappings";
import EeidMappings from "./components/eeidMappings";

export default function ClientDetailsInfo() {
  const prams = useParams();
  const id = parseInt(prams.id);
  const [activeTabKey2, setActiveTabKey2] = useState("1");
  const [clientDetails, setClientDetails] = useState(null);

  const onTab2Change = (key) => {
    setActiveTabKey2(key);
  };

  useEffect(() => {
    const getClientDetails = async () => {
      const data = await Client.getClientDetails(id);
      setClientDetails(data);
    };
    if (clientDetails === null) getClientDetails();
  }, [clientDetails, id]);

  const tabListNoTitle = [
    { key: "1", tab: "Client Details" },
    { key: "2", tab: "File Configuration" },
    { key: "3", tab: "E-File Mappings" },
    { key: "4", tab: "F-File Mappings" },
    { key: "5", tab: "O-File Mappings" },
    { key: "6", tab: "EEID Mappings" },
    { key: "7", tab: "Jobs" },
  ];

  const contentListNoTitle = {
    1: <ClientDetails clientDetails={clientDetails} id={id} setClientDetails={setClientDetails} />,
    2: <FileConfiguration clientDetails={clientDetails} setClientDetails={setClientDetails} id={id} />,
    3: <EFileMappings id={id} />,
    4: <FFileMappings id={id} />,
    5: <OFileMappings id={id} />,
    6: <EeidMappings id={id} />,
    7: <Jobs id={id} clientDetails={clientDetails} />,
  };

  return (
    <Card
      className="client-details_info-card"
      style={{ width: "100%" }}
      tabList={tabListNoTitle}
      activeTabKey={activeTabKey2}
      tabBarExtraContent={
        <Link to="/clients">
          <i className="icon-leftarrow"></i>
          <h4>{clientDetails?.company}</h4>
        </Link>
      }
      onTabChange={(key) => {
        onTab2Change(key);
      }}
    >
      {contentListNoTitle[activeTabKey2]}
    </Card>
  );
}
