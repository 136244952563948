import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import PropTypes from "prop-types";

OFileMappingForms.propTypes = {
  oFileColumns: PropTypes.array,
  setOFileColumns: PropTypes.array,
  disabled: PropTypes.bool,
  filterSelectOptions: PropTypes.func,
  handleFileSelection: PropTypes.func,
  recentlyHoveredFileId: PropTypes.number,
  fileConfiguration: PropTypes.object,
  setColumnOptions: PropTypes.array,
  filterColumns: PropTypes.func,
  highlightColumns: PropTypes.func,
  recentlyHoveredFileColumnValue: PropTypes.object,
  recentlyHoveredColumnValue: PropTypes.object,
  getUniqueCSVColumns: PropTypes.func,
  allForms: PropTypes.object,
  columnOptions: PropTypes.object,
  form: PropTypes.object,
};

export default function OFileMappingForms({
  oFileColumns,
  setOFileColumns,
  disabled,
  filterSelectOptions,
  handleFileSelection,
  recentlyHoveredFileId,
  fileConfiguration,
  setColumnOptions,
  filterColumns,
  highlightColumns,
  recentlyHoveredFileColumnValue,
  recentlyHoveredColumnValue,
  getUniqueCSVColumns,
  allForms,
  columnOptions,
  form,
}) {
  useEffect(() => form.submit(), []);
  return (
    <>
      {oFileColumns.map((file, key) => (
        <Form className="mapping-form" name={"form" + file.id} key={key + 1}>
          <div className="mapping-form-border">
            <Row gutter={[20, 20]}>
              <Col xs={24} lg={12} xl={13}>
                <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
                  {({ getFieldValue }) => (
                    <Row gutter={[26, 20]}>
                      <Col xs={24} sm={8} xl={9}>
                        <label>
                          {file.type === "IR" && <span style={{ color: "red" }}>* </span>}
                          {file.columnNumber + " - " + file.name + ":"}
                        </label>
                      </Col>
                      <Col xs={24} sm={6} xl={6}>
                        <Form.Item name="oFileColumnsId" hidden>
                          <Input></Input>
                        </Form.Item>
                        <Form.Item
                          className="mb-0"
                          name={"file"}
                          rules={[
                            {
                              required: file.type === "IR" || (fileConfiguration?.isUpdated && !!getFieldValue("column")),
                              message: "File must be selected.",
                            },
                          ]}
                        >
                          <Select
                            disabled={disabled}
                            showSearch
                            filterOption={(inputValue, option) => filterSelectOptions(inputValue, option)}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            allowClear
                            placeholder="Select File"
                            onMouseEnter={() => {
                              recentlyHoveredFileId.current = getFieldValue("file");
                              recentlyHoveredFileColumnValue.current = getFieldValue("column");
                            }}
                            onChange={(fileConfigId) => {
                              // Error handling if file is selected then column must be selected as well, in case of fileConfiguration updated
                              if (fileConfiguration?.isUpdated) {
                                const form = allForms.current?.["form" + file.id];
                                const columnValue = getFieldValue("column");

                                if (!fileConfigId && columnValue) {
                                  form?.setFields([{ name: "column", errors: null }]);
                                } else if (!fileConfigId && !columnValue) {
                                  form?.setFields([{ name: "column", errors: null }]);
                                } else if (fileConfigId && !columnValue) {
                                  form?.setFields([{ name: "column", errors: ["Column must be selected."] }]);
                                }
                              }

                              handleFileSelection(fileConfigId, "form" + file.id, file.id);
                            }}
                          >
                            {fileConfiguration?.files.map((fileConfig, index) => (
                              <Select.Option key={index + 1} value={fileConfig.id}>
                                {fileConfig.fileNamePrefix}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={9} xl={8}>
                        <Form.Item
                          className="mb-0"
                          name={"column"}
                          rules={[
                            {
                              required: file.type === "IR" || (fileConfiguration?.isUpdated && !!getFieldValue("file")),
                              message: "Column must be selected.",
                            },
                          ]}
                        >
                          <Select
                            disabled={disabled}
                            showSearch
                            filterOption={(inputValue, option) => filterSelectOptions(inputValue, option)}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            allowClear
                            placeholder="Select Column"
                            onMouseEnter={() => (recentlyHoveredColumnValue.current = getFieldValue("column"))}
                            onChange={(value) => {
                              const form = allForms.current?.["form" + file.id];
                              const fileValue = getFieldValue("file");

                              // Error handling if column is selected then file must be selected as well, in case of fileConfiguration updated
                              if (fileConfiguration?.isUpdated) {
                                if (!value && fileValue) {
                                  form?.setFields([{ name: "file", errors: null }]);
                                } else if (!value && !fileValue) {
                                  form?.setFields([{ name: "file", errors: null }]);
                                } else if (value && !fileValue) {
                                  form?.setFields([{ name: "file", errors: ["File must be selected."] }]);
                                }
                              } else {
                                form?.setFieldsValue({ valueMappings: [] });
                              }

                              // Filter Column Options
                              // const filteredColumnOption = filterColumns(value, "form" + file.id, null);
                              // setColumnOptions({ ...columnOptions, ...filteredColumnOption });

                              // Highlight Column Options
                              const highlightedColumnOption = highlightColumns(value, "form" + file.id, null);
                              setColumnOptions({ ...columnOptions, ...highlightedColumnOption });

                              // Populate replace value in mappings by changing column option
                              const autoCompleteTagOptions = getUniqueCSVColumns(fileValue, value);
                              oFileColumns[key] = { ...oFileColumns[key], availableOriginalOptions: autoCompleteTagOptions };
                              setOFileColumns(oFileColumns);
                            }}
                          >
                            {columnOptions?.["form" + file.id] !== undefined
                              ? columnOptions?.["form" + file.id].map((option, index) => {
                                  return (
                                    <Select.Option key={index + 1} value={option?.value}>
                                      {option.isSelected ? <b>{option?.value}</b> : option?.value}
                                    </Select.Option>
                                  );
                                })
                              : ""}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>

              {/* Mappings */}
              {file.mappingAllowed === true ? (
                <Col xs={24} lg={12} xl={11}>
                  <div className="mapping-replace-row">
                    {/* FORM LIST */}
                    <Form.List
                      name={"valueMappings"}
                      // initialValue={[{originalValue: "", mappedValue: ""}]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map(({ key, name }, index) => (
                            <Row gutter={[20, 20]} key={key}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  className="mb-0"
                                  label="Replace"
                                  name={[name, "originalValue"]}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Required",
                                  //   },
                                  // ]}
                                >
                                  <AutoComplete
                                    allowClear
                                    disabled={disabled}
                                    options={file?.availableOriginalOptions ?? []}
                                    placeholder="Write keyword"
                                    filterOption={(inputValue, option) => filterSelectOptions(inputValue, option)}
                                  />
                                  {/* <Input placeholder="Write keyword" disabled={disabled} /> */}
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <div className="mapping-input">
                                  <Form.Item
                                    className="mb-0 mapping-form-item"
                                    label="to :"
                                    name={[name, "mappedValue"]}
                                    rules={[
                                      noTrailingSpaceAllowedRule(),
                                      // {
                                      //   required: true,
                                      //   message: "Required",
                                      // },
                                    ]}
                                  >
                                    {file?.availableOptions?.length > 0 ? (
                                      <Select allowClear placeholder="Select a value" disabled={disabled}>
                                        {file?.availableOptions?.map((val, index) => (
                                          <Select.Option key={index + 1} value={val}>
                                            {val}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    ) : (
                                      <Input placeholder="Write keyword" disabled={disabled} allowClear />
                                    )}
                                  </Form.Item>
                                  <Form.Item className="mb-0">
                                    {disabled ? (
                                      <Button
                                        type="link"
                                        disabled={disabled}
                                        icon={<DeleteOutlined className="mapping-plus-btn" style={{ fontSize: "20px" }} />}
                                      ></Button>
                                    ) : (
                                      <DeleteOutlined
                                        className="mapping-plus-btn"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => remove(name)}
                                      />
                                    )}
                                    {/* <Button className="mapping-plus-btn" type="primary" icon={<DeleteOutlined />} onClick={() => remove(name)} /> */}
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          ))}
                          <Form.Item>
                            <Form.ErrorList errors={errors} />
                            <Button block type="dashed" disabled={disabled} icon={<PlusOutlined />} onClick={() => add()}>
                              Add mappings
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Form>
      ))}
    </>
  );
}
