import React, { useEffect } from "react";
import { Button, Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import Client from "models/client/client";
import { maxLengthValidation, noSpecialCharactersRule, noTrailingSpaceAllowedRule } from "utilities/generalUtility";

export default function ClientDetails({ clientDetails, id, setClientDetails }) {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const { sftpUsername, ...restPayload } = values;
      await Client.updateClient(restPayload, id);
      const data = await Client.getClientDetails(id);
      setClientDetails(data);
      message.success("Client has been updated!");
    } catch (error) {
      console.error(error);
    }
  };

  const onFinishFailed = (err) => {
    console.log("Client not updated", err);
  };

  useEffect(() => {
    form.setFieldsValue(clientDetails);
  }, [clientDetails, form]);

  return (
    <Form
      className="client-details-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={[20, 20]}>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="Company Name:"
            name="company"
            rules={[
              { required: true, message: "Please enter your company name!" },
              {
                validator: async (_, value) => maxLengthValidation(value, 100, "Company name"),
              },
            ]}
          >
            <Input placeholder="Enter your company name" />
          </Form.Item>
        </Col>
        {/* <Col sm={12} xs={24}>
            <Form.Item
              className="mb-0"
              label="Phone Number:"
              name="phone"
              rules={[
                { required: true, message: "Please enter your phone number!" },
                {
                  validator: async (_, value) => maxLengthValidation(value, 100, "Phone number", "Digits"),
                },
              ]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>
          </Col> */}

        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="Employer Reference Code:"
            name="employerReferenceCode"
            rules={[
              {
                required: true,
                message: "Please enter your employer reference code!",
              },
            ]}
          >
            <Input placeholder="Enter your employer reference code" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="Start Day Of Week:"
            name="startDayOfWeek"
            rules={[
              {
                required: true,
                message: "Please select your start day of the week!",
              },
            ]}
          >
            <Select placeholder="Select your start day of the week">
              <Select.Option value="1">Monday</Select.Option>
              <Select.Option value="2">Tuesday</Select.Option>
              <Select.Option value="3">Wednesday</Select.Option>
              <Select.Option value="4">Thursday</Select.Option>
              <Select.Option value="5">Friday</Select.Option>
              <Select.Option value="6">Saturday</Select.Option>
              <Select.Option value="0">Sunday</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="STD Hours:"
            name="standardHours"
            rules={[
              { required: true, message: "Please enter your standard hours!" },
              {
                validator: async (_, number) => {
                  maxLengthValidation(number, 100, "Standard hours", "Digits");
                  if (number < 0 || number > 80) {
                    return Promise.reject(new Error("Standard hours should between 0-80!"));
                  }
                },
              },
            ]}
          >
            <InputNumber
              className="input-number"
              placeholder="Enter your standard hours"
              type="number"
              formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
              parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
            />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="50 in 75:"
            name="fiftyInSeventyFive"
            rules={[{ required: true, message: "Please select your 50 in 75!" }]}
          >
            <Select placeholder="Select your 50 in 75">
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="HR Team Email: "
            name="hrTeamEmail"
            rules={[
              { required: true, message: "Please enter your hr team email!" },
              { type: "email", message: "The input is not valid E-mail!" },
              { validator: async (_, value) => maxLengthValidation(value, 100, "HR team email") },
            ]}
          >
            <Input placeholder="Enter your hr team email" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="File Disposition:"
            name="fileDisposition"
            rules={[{ required: true, message: "Please select your disposition!" }]}
          >
            <Select placeholder="Select your file disposition">
              <Select.Option value={"DONT"}>Don't Send</Select.Option>
              <Select.Option value={"PROD"}>Send to Production</Select.Option>
              <Select.Option value={"STAGE"}>Send to Staging</Select.Option>
              <Select.Option value={"BOTH"}>Send to Both</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="Status:"
            name="status"
            initialValue={"ACTIVE"}
            rules={[{ required: true, message: "Please select your status!" }]}
          >
            <Select placeholder="Select your status">
              <Select.Option value={"ACTIVE"}>Active</Select.Option>
              <Select.Option value={"IMPLEMENTATION"}>Implementation</Select.Option>
              <Select.Option value={"SUSPENDED"}>Suspended</Select.Option>
              <Select.Option value={"TERMINATED"}>Terminated</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            label="Percentage of Change:"
            name="eFileChangePercentage"
            rules={[
              { required: true, message: "Please enter your percentage of change!" },
              {
                validator: async (_, number) => {
                  maxLengthValidation(number, 100, "Percentage of change", "Digits");
                  if (number < 0 || number > 100) {
                    return Promise.reject(new Error("Percentage of change should between 0-100!"));
                  }
                },
              },
            ]}
          >
            <InputNumber className="input-number" placeholder="Enter your percentage of change" type="number" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}></Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="Preprocessor SFTP Username:"
            name="sftpUsername"
            rules={[
              noTrailingSpaceAllowedRule(),
              noSpecialCharactersRule(),
              { required: true, message: "Please enter your preprocessor sftp username!" },
              { validator: async (_, value) => maxLengthValidation(value, 100, "Preprocessor SFTP Username") },
            ]}
          >
            <Input disabled placeholder="Enter your preprocessor sftp username" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            className="mb-0"
            label="Preprocessor SFTP Password:"
            name="sftpPassword"
            rules={[
              {
                required: true,
                message: "Please enter your preprocessor sftp password!",
              },
              {
                validator: async (_, value) => maxLengthValidation(value, 100, "Preprocessor SFTP Password"),
              },
            ]}
          >
            <Input.Password placeholder="Enter your preprocessor sftp password" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="AT Username Stage:"
            name="atUsernameStage"
            rules={[
              noTrailingSpaceAllowedRule(),
              noSpecialCharactersRule(),
              {
                required: true,
                message: "Please enter your at-username stage!",
              },
              {
                validator: async (_, value) => maxLengthValidation(value, 100, "AT Username Stage"),
              },
            ]}
          >
            <Input placeholder="Enter your at-username stage" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="AT Password Stage: "
            name="atPasswordStage"
            rules={[
              {
                required: true,
                message: "Please enter your at-password stage!",
              },
              {
                validator: async (_, value) => maxLengthValidation(value, 100, "AT Password Stage"),
              },
            ]}
          >
            <Input.Password placeholder="Enter your at-password stage" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="AT Username Prod:"
            name="atUsernameProd"
            rules={[
              noTrailingSpaceAllowedRule(),
              noSpecialCharactersRule(),
              {
                required: true,
                message: "Please enter your at-username prod!",
              },
              {
                validator: async (_, value) => maxLengthValidation(value, 100, "AT Username Prod"),
              },
            ]}
          >
            <Input placeholder="Enter your at-username prod" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item
            className="mb-0"
            label="AT Password Prod: "
            name="atPasswordProd"
            rules={[
              {
                required: true,
                message: "Please enter your at-password prod!",
              },
              {
                validator: async (_, value) => maxLengthValidation(value, 100, "AT Password Prod"),
              },
            ]}
          >
            <Input.Password placeholder="Enter your at-password prod" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className="client-details-btn mb-0">
        {/* <Button className="cancel-btn" size="large">
            Cancel
          </Button> */}
        <Button className="gradient-btn" size="large" htmlType="submit">
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
}
