const K = {
  Network: {
    URL: {
      // Development
      Base: process.env.REACT_APP_BASE_URL,
      BaseAPI: process.env.REACT_APP_BASE_API_URL,
      DomainName: process.env.REACT_APP_CLIENT_DOMAIN_NAME,
      Timeout: process.env.REACT_APP_TIMEOUT,
      TenantURL: (domainPrefix = "") => {
        return process.env.REACT_APP_PROTOCOL + "://" + domainPrefix + process.env.REACT_APP_TENANT_PARTIAL_URL;
      },
      Client: {
        BaseHost: process.env.REACT_APP_CLIENT_BASE_HOST,
        BasePort: process.env.REACT_APP_CLIENT_BASE_PORT,
      },

      Protocol: process.env.REACT_APP_PROTOCOL,

      // Assignment
      LoginUser: "/auth/login",

      // Forget password
      ForgotPassword: "/user/send_password_reset_token",

      //Reset password
      ResetPassword: "/user/reset_password",

      /**
       * -------------
       * Client Calls
       * -------------
       */

      // Fetch Clients
      FetchClients: "/clients/get-all",

      // Create New Client
      CreateNewClient: "/clients/create",

      // Get Specific Client Details
      GetClientDetails: "/clients/details/",

      // Update Client
      UpdateClient: "/clients/update/",

      // Delete Client
      DeleteClient: "/clients/delete",

      // Get File Configurations For Client
      GetFileConfigurations: "/file-configurations/get/",

      // Get File Configurations For Client
      GetFileConfiguration: "/file-configurations/get/",

      // Create File Configurations For Client
      CreateFileConfigurations: "/file-configurations/create",

      // Update File Configurations For Client
      UpdateFileConfigurations: "/file-configurations/update",

      // Get E-File Columns For Client
      GetEFileColumns: "/efile-columns/get-all",

      // Get Mappings For Client
      GetMappings: "/mappings/get/",

      // Create Mappings For Client
      CreateMappings: "/mappings/create",

      // Update Mappings For Client
      UpdateMappings: "/mappings/update",

      // Get F-File Columns For Client
      GetFFileColumns: "/f-file-columns/get-all",

      // Get Mappings For Client
      GetFFileMappings: "/f-records-mappings/get/",

      // Create Mappings For Client
      CreateFFileMappings: "/f-records-mappings/create",

      // Update Mappings For Client
      UpdateFFileMappings: "/f-records-mappings/update",

      // Get F-File Columns For Client
      GetOFileColumns: "/o-file-columns/get-all",

      // Get Mappings For Client
      GetOFileMappings: "/o-records-mappings/get/",

      // Create Mappings For Client
      CreateOFileMappings: "/o-records-mappings/create",

      // Update Mappings For Client
      UpdateOFileMappings: "/o-records-mappings/update",

      // Get E File Jobs For Client
      GetEFileJobs: "/efile-jobs/get-all/",

      // Get E File Jobs Stats For Client
      GetEFileJobsStats: "/efile-jobs/get-stats/",

      // Get F File Jobs For Client
      GetFFileJobs: "/f-file-jobs/get-all/",

      // Get F File Jobs Stats For Client
      GetFFileJobsStats: "/f-file-jobs/get-stats/",

      // Get O File Jobs For Client
      GetOFileJobs: "/o-file-jobs/get-all/",

      // Get O File Jobs Stats For Client
      GetOFileJobsStats: "/o-file-jobs/get-stats/",

      //Create Emplotyee id Mappings For Client
      CreateEeidMappings: "/employee-id-mappings/create",

      /**
       * -----------
       * User Calls
       * -----------
       */

      // Fetch Users
      FetchUsers: "/users/get-all",

      // Create New User
      CreateNewUser: "/users/create",

      // Delete User
      DeleteUser: "/users/delete",

      // Update User Status
      UpdateUserStatus: "/users/update",
    },
    Method: {
      GET: "GET",
      POST: "POST",
      PUT: "PUT",
      PATCH: "PATCH",
      DELETE: "DELETE",
    },
    Header: {
      ContentType: "Content-Type",
      ApplicationJson: "application/json",
      Default: (token = "") => ({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "ngrok-skip-browser-warning": true,
      }),
      Authorization: (token = "") => ({
        Authorization: "Bearer " + token,
      }),
      Type: {
        Json: "json",
        File: "file",
      },
    },
    StatusCode: {
      Unauthorized: 401,
    },
  },
  Actions: {
    // General part of action
    CREATE: "CREATE",
    UPSERT: "UPSERT",
    DELETE: "DELETE",
    DELETE_ALL: "DELETE_ALL",
    SET: "SET",
  },
  Cookie: {
    Key: {
      User: "user",
      EncryptionKey: "logged_in_user",
    },
  },
  Roles: {
    Admin: "Admin",
    User: "User",
  },
  NullReplacement: "N/A",
  Status: {
    COMPLETED: "green",
    SUBMITTED: "cyan",
    WARNING: "orange",
    FAILED: "red",
    // for clients
    ACTIVE: "green",
    IMPLEMENTATION: "cyan",
    SUSPENDED: "orange",
    TERMINATED: "red",
  },
};
export default K;
