import { MinusOutlined, PlusOutlined, ExclamationCircleFilled, InboxOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Form, Input, InputNumber, List, message, Modal, Radio, Row, Select, Skeleton, Typography } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import Client from "models/client/client";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";
import { storeFileConfiguration } from "redux/client/clientSlice";
import { maxLengthValidation, fileSheetReader, validateFileConfigExcelData, createFileConfigPayload } from "utilities/generalUtility";

const { Text, Title } = Typography;

export default function FileConfiguration({ clientDetails, setClientDetails, id }) {
  const [warningModal, setWarningModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState({ errors: [], payload: null });
  const [showUploadList, setShowUploadList] = useState(false);
  const [numberOfFiles, setNumberOfFiles] = useState(1);
  const [selectedTab, setSelectedTab] = useState(clientDetails?.repeatType ?? "WEEKLY");
  const [files, setFiles] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    let fileConfigurations = [];

    values.files.map((file, key) =>
      fileConfigurations.push({
        type: file?.type ?? null,
        employeeIdColumn: file.headerRow === 0 ? null : file.employeeIdColumn,
        fileNamePrefix: file.fileNamePrefix,
        headerRow: file.headerRow,
      }),
    );

    let payload = {
      clientId: id,
      repeatType: selectedTab,
      repeatDay: values?.repeatDay ?? null,
      repeatFrequency: values?.repeatFrequency ?? null,
      monthlyDate: values?.monthlyDate ?? null,
      biMonthlyDateOne: values?.biMonthlyDateOne ?? null,
      biMonthlyDateTwo: values?.biMonthlyDateTwo ?? null,
      fileConfigurations,
    };

    // Create or Update file configuration
    files?.length === 0 ? await Client.createFileConfigurations(payload) : await Client.updateFileConfigurations(payload);

    // Refetch the file configuration
    getFileConfigurations();
    setClientDetails((data) => ({ ...data, ...payload }));

    await dispatch(
      storeFileConfiguration({
        ...(files.length === 0 ? {} : { isUpdated: true }) /* Maintain store if file configuration is updated */,
        repeatDay: values?.repeatDay,
        repeatFrequency: values?.repeatFrequency,
        files: fileConfigurations,
      }),
    );
    setWarningModal(false);
    message.success("File Configuration Saved");
  };

  const getFileConfigurations = useCallback(async () => {
    const data = await Client.getFileConfigurations(id);
    setNumberOfFiles(data?.length);
    setFiles(data);
  }, [id]);

  const setFileFrequencyValues = (fileFrequency) => {
    form.setFieldsValue({
      repeatDay: fileFrequency?.repeatDay,
      repeatFrequency: fileFrequency?.repeatFrequency,
      monthlyDate: fileFrequency?.monthlyDate,
      biMonthlyDateOne: fileFrequency?.biMonthlyDateOne,
      biMonthlyDateTwo: fileFrequency?.biMonthlyDateTwo,
    });
  };

  useEffect(() => {
    setFileFrequencyValues(clientDetails);
  }, [clientDetails, form]);

  useEffect(() => {
    files.length === 0 ? getFileConfigurations() : form.setFieldsValue({ files: files });
  }, [files, form, getFileConfigurations]);

  const readFiles = async (file) => {
    try {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        message.error(`${file.name} is not an XLSX file`);
        setUploadedFileData({ errors: [`${file.name} is not an XLSX file`], payload: null });
        setShowUploadList(false);
        return false;
      }

      // Read the file
      const res = await trackPromise(fileSheetReader(file, "File Configuration"));

      // Validate data
      const errors = validateFileConfigExcelData(res);

      // Create the payload object
      const payload = createFileConfigPayload(res, id);

      // Update the error and payload state
      if (errors.length === 0) {
        setShowUploadList(true);
        message.success(`${file.name} file uploaded successfully.`);
        setUploadedFileData({ errors, payload });
      } else {
        setShowUploadList(false);
        message.error(`${file.name} file upload failed.`);
        setUploadedFileData({ errors, payload: null });
      }
    } catch (error) {
      setUploadedFileData({ errors: [error], payload: null });
      message.error(error);
    }
  };

  const uploadProps = {
    maxCount: 1,
    multiple: false,
    action: "",
    showUploadList: showUploadList && {
      showRemoveIcon: false,
    },
    headers: { authorization: "authorization-text" },
    beforeUpload: (file) => {
      readFiles(file);

      // To prevent send network call
      return false;
    },
  };

  const populateFileConfig = () => {
    const data = uploadedFileData?.payload;
    setSelectedTab(data?.repeatType);
    setFileFrequencyValues(data);
    setNumberOfFiles(data?.fileConfigurations?.length);
    setFiles(data?.fileConfigurations);
    setUploadModal(false);
  };

  return (
    <>
      {/* <Spin size="large"/> */}

      <Form className="file-configuration-form" layout="vertical" onFinish={onFinish} form={form}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Radio.Group
            value={selectedTab}
            onChange={(selectedTab) => setSelectedTab(selectedTab.target.value)}
            style={{ marginBottom: 16 }}
            buttonStyle="solid"
            optionType="button"
          >
            <Radio.Button value="WEEKLY">Weekly</Radio.Button>
            <Radio.Button value="MONTHLY">Monthly</Radio.Button>
            <Radio.Button value="BI_MONTHLY">Bi Monthly</Radio.Button>
          </Radio.Group>

          <Button type="primary" className="upload-btn" onClick={() => setUploadModal(true)}>
            Upload File Configurations
          </Button>
        </div>

        {selectedTab === "WEEKLY" && (
          <Row gutter={[20, 20]}>
            <Col sm={12} xs={24}>
              <Form.Item
                name="repeatDay"
                className="mb-0"
                label="Repeat Day:"
                rules={[{ required: true, message: "Please choose your repeat day!" }]}
              >
                <Select
                  placeholder="Select repeat day"
                  options={[
                    { value: 1, label: "Monday" },
                    { value: 2, label: "Tuesday" },
                    { value: 3, label: "Wednesday" },
                    { value: 4, label: "Thursday" },
                    { value: 5, label: "Friday" },
                    { value: 6, label: "Saturday" },
                    { value: 0, label: "Sunday" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                name="repeatFrequency"
                className="mb-0"
                label="Repeat Frequency:"
                rules={[
                  { required: true, message: "Please input your repeat frequency!" },
                  {
                    validator: async (_, number) => {
                      maxLengthValidation(number, 100, "Repeat frequency", "Digits");
                      if (number < 0) return Promise.reject(new Error("Repeat frequency shouldn't be less than 0"));
                    },
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  style={{ width: "100%" }}
                  placeholder="Select repeat frequency"
                  formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
                  parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedTab === "MONTHLY" && (
          <Row gutter={[20, 20]}>
            <Col sm={12} xs={24}>
              <Form.Item
                className="mb-0"
                label="Monthly Date:"
                name="monthlyDate"
                rules={[
                  { required: true, message: "Please input your monthly date!" },
                  {
                    validator: async (_, number) => {
                      maxLengthValidation(number, 100, "Monthly date", "Digits");
                      if (number < 1 || number > 31) return Promise.reject(new Error("Monthly date should between 1-31"));
                    },
                  },
                ]}
              >
                <InputNumber
                  className="input-number"
                  placeholder="Enter monthly date"
                  type="number"
                  formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
                  parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedTab === "BI_MONTHLY" && (
          <Row gutter={[20, 20]}>
            <Col sm={12} xs={24}>
              <Form.Item
                className="mb-0"
                label="Date One:"
                name="biMonthlyDateOne"
                rules={[
                  { required: true, message: "Please input date one!" },
                  {
                    validator: async (_, number) => {
                      maxLengthValidation(number, 100, "Bi-Monthly date one", "Digits");
                      if (number < 1 || number > 15) return Promise.reject(new Error("Date one should between 1-15"));
                    },
                  },
                ]}
              >
                <InputNumber
                  className="input-number"
                  placeholder="Enter date one"
                  type="number"
                  formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
                  parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                className="mb-0"
                label="Date Two:"
                name="biMonthlyDateTwo"
                rules={[
                  {
                    required: true,
                    message: "Please input date two!",
                  },
                  {
                    validator: async (_, number) => {
                      maxLengthValidation(number, 100, "Bi-Monthly date two", "Digits");
                      if (number < 16 || number > 31) {
                        return Promise.reject(new Error("Date two should between 16-31"));
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  className="input-number"
                  placeholder="Enter date two"
                  type="number"
                  formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
                  parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <div className="configuration-file-count">
          <Text>
            File Count: <b>{numberOfFiles}</b>
          </Text>
        </div>
        <Form.List
          name="files"
          initialValue={[{ fileNamePrefix: "", headerRow: "" }]}
          rules={[
            {
              validator: async (_, files) => {
                if (!files || files.length < 1) {
                  return Promise.reject(new Error("At least 1 file should be added"));
                }
                const uniqueValues = new Set(files.map((v) => v?.fileNamePrefix));
                if (uniqueValues.size < files.length) {
                  return Promise.reject(new Error("Duplicate or Empty File Name Prefixes are not allowed"));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }, index) => (
                <Form.Item key={key} shouldUpdate={(prevValues, currentValues) => prevValues.files !== currentValues.files}>
                  {({ getFieldValue }) => (
                    <Row gutter={[20, 20]} key={key} style={{ marginBottom: "10px" }}>
                      <Col sm={getFieldValue("files")?.[index]?.headerRow === 0 ? 8 : 6} xs={24}>
                        <Form.Item
                          className="mb-0"
                          label="Type:"
                          name={[name, "type"]}
                          rules={[
                            { required: true, message: "Please input type." },
                            { validator: async (_, value) => maxLengthValidation(value, 100, "Type") },
                          ]}
                        >
                          <Select
                            placeholder="Select file type"
                            options={[
                              { value: "E", label: "E" },
                              { value: "F", label: "F" },
                              { value: "O", label: "O" },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      {/* Hide employeeIdColumn if headerRow is 0 */}
                      {getFieldValue("files")?.[index]?.headerRow !== 0 && (
                        <Col sm={6} xs={24}>
                          <Form.Item
                            className="mb-0"
                            label="Employee Id Column Name:"
                            name={[name, "employeeIdColumn"]}
                            rules={[
                              { required: true, message: "Please input employee id column name." },
                              { validator: async (_, value) => maxLengthValidation(value, 100, "Employee id column name") },
                            ]}
                          >
                            <Input placeholder="Employee id column name" />
                          </Form.Item>
                        </Col>
                      )}

                      <Col sm={getFieldValue("files")?.[index]?.headerRow === 0 ? 8 : 6} xs={24}>
                        <Form.Item
                          className="mb-0"
                          label="File Name Prefix:"
                          name={[name, "fileNamePrefix"]}
                          rules={[
                            { required: true, message: "Please input file name prefix." },
                            { validator: async (_, value) => maxLengthValidation(value, 100, "File name prefix") },
                          ]}
                        >
                          <Input placeholder="File name prefix" />
                        </Form.Item>
                      </Col>
                      <Col sm={getFieldValue("files")?.[index]?.headerRow === 0 ? 8 : 6} xs={24}>
                        <div className="header-row">
                          <Form.Item
                            className="mb-0 header-row-input"
                            label="Header Row:"
                            name={[name, "headerRow"]}
                            rules={[
                              { required: true, message: "Please input header row." },
                              {
                                validator: async (_, number) => {
                                  maxLengthValidation(number, 100, "Header row", "Digits");
                                  if (getFieldValue("files")?.[index]?.type !== "E" && number < 1)
                                    return Promise.reject(new Error("Header row shouldn't be less than 1, if Type is F or O"));
                                  else if (number < 0) return Promise.reject(new Error("Header row shouldn't be less than 0"));
                                },
                              },
                            ]}
                          >
                            <InputNumber
                              type="number"
                              className="input-number"
                              placeholder="Header row"
                              maxLength={100}
                              formatter={(value) => `${value}`.replace(/\.\d*/, "")} // Remove decimal part
                              parser={(value) => value.replace(/[^\d]/g, "")} // Allow only digits
                            />
                          </Form.Item>
                          <Form.Item className="mb-0 header-row-plus">
                            <Button
                              type="primary"
                              icon={<MinusOutlined />}
                              onClick={() => {
                                remove(name);
                                setNumberOfFiles((prev) => prev - 1);
                              }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              ))}
              <br />
              <Form.Item>
                <Form.ErrorList errors={errors} />
                <Button
                  type="primary"
                  className="add-more-btn"
                  block
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add();
                    setNumberOfFiles((prev) => prev + 1);
                  }}
                >
                  Add file
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item className="client-details-btn mb-0 ">
          {/* <Button className="cancel-btn" size="large">
            Cancel
          </Button> */}
          <Button
            className="gradient-btn"
            size="large"
            type="primary"
            {...(files.length === 0 ? { htmlType: "submit" } : { onClick: () => setWarningModal(true) })}
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>

      {/* Update file configuration modal */}
      <Modal
        title={
          <Text strong>
            <ExclamationCircleFilled style={{ color: "#faad14" }} /> Warning
          </Text>
        }
        open={warningModal}
        onOk={() => form.submit()}
        onCancel={() => setWarningModal(false)}
      >
        <p>Updating file configuration will remove all the configured file names from mappings!</p>
      </Modal>

      {/* Upload file configuration */}
      <Modal
        title="Upload File Configurations"
        open={uploadModal}
        onOk={() => populateFileConfig()}
        onCancel={() => setUploadModal(false)}
        width="50%"
        footer={[
          <Button key="back" onClick={() => setUploadModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => populateFileConfig()}
            disabled={uploadedFileData?.errors.length !== 0 || uploadedFileData?.payload === null}
          >
            Confirm
          </Button>,
        ]}
      >
        {uploadedFileData?.errors?.length > 0 && (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Title level={5} className="pr-1">
                  Errors{" "}
                </Title>
                <Badge style={{ paddingTop: "1px" }} count={uploadedFileData?.errors.length} overflowCount={999} />
              </div>
            </div>

            <div
              id="scrollableDiv"
              style={{
                maxHeight: 150,
                overflow: "auto",
                padding: "0 0",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <InfiniteScroll
                dataLength={uploadedFileData?.errors.length}
                next={""}
                hasMore={false}
                loader={<Skeleton paragraph={{ rows: 1 }} />}
                scrollableTarget="scrollableDiv"
              >
                <List
                  size="small"
                  dataSource={uploadedFileData?.errors}
                  renderItem={(item, key) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <div className="d-flex">
                            <span className="mr-1">{key + 1}.</span>
                            <span>{item}</span>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
            <br />
          </>
        )}

        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload (Only 1 file is allowed)</p>
          <p className="ant-upload-hint">Uploading the file will overwrite the existing data</p>
        </Dragger>
      </Modal>
    </>
  );
}
