import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clients: [],
  fileConfiguration: null
};

export const clientSlice = createSlice({
  name: "clientSlice",
  initialState,
  reducers: {
    storeClients: (state, action) => {
      state.clients = [...action.payload];
    },
    storeFileConfiguration: (state, action) => {
      state.fileConfiguration = action.payload;
    }
  },
});

export const { storeClients, storeFileConfiguration } = clientSlice.actions;

export default clientSlice.reducer;