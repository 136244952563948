import { SearchOutlined } from "@ant-design/icons";
import { Card, Button, Input, Space, Table, Modal, Row, Col, Form, message, Popconfirm, Switch } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import User from "models/user/user";
import { useSelector, useDispatch } from "react-redux";
import { storeUsers } from "redux/user/userSlice";
import { getDateAndTime } from "utilities/dateUtility";
import Highlighter from "react-highlight-words";
import { maxLengthValidation } from "utilities/generalUtility";
import md5 from "md5";

export default function Users() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [formRef] = Form.useForm();
  const usersData = useSelector((state) => state.users);
  const searchInput = useRef(null);
  const dispatch = useDispatch();

  const fetchUsers = useCallback(async () => {
    let data = await User.fetchUsers();
    data = data.map((user) => (user?.createdAt !== undefined ? { ...user, createdAt: getDateAndTime(user.createdAt) } : user));
    await dispatch(storeUsers(data));
  }, [dispatch]);

  useEffect(() => {
    if (usersData.length !== 0) fetchUsers();
  }, [fetchUsers]);

  const onFormFinish = async (values) => {
    values["password"] = md5(values.password);
    await User.createNewUser(values);
    message.success("User has been created successfully!");
    fetchUsers();
    setIsModalOpen(false);
  };

  const onFormFinishFailed = (err) => {
    console.log("Create New User Form failed to submit", err);
  };

  const deleteUser = async (id) => {
    await User.deleteUser(id);
    message.success("User has been deleted successfully!");
    fetchUsers();
  };

  const updateUserStatus = async (id, status) => {
    const body = { isActive: status };
    await User.updateUserStatus(id, body);
    message.success(`User ${status ? "activated" : "deactivated"}  successfully!`);
    fetchUsers();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const clearFormFields = () => {
    formRef.resetFields();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const filterIconComponent = (filtered) => {
    return <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />;
  };

  const filterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }, dataIndex) => {
    return (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            className="gradient-btn"
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            className="cancel-btn"
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""], confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterIcon: (filtered) => filterIconComponent(filtered),
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) setTimeout(() => searchInput.current?.select(), 100);
    },
    filterDropdown: (data) => filterDropdownComponent(data, dataIndex),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
    },

    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      width: "30%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Creation Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "30%",
      ...getColumnSearchProps("createdAt"),
      // render: (_, record) =>  getDateAndTime(record.createdAt),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      width: "15%",
      // ...getColumnSearchProps("isActive"),
      render: (_, record) => {
        return (
          <Switch
            defaultChecked={record.isActive}
            checkedChildren="Yes"
            unCheckedChildren="No"
            className="table-switch"
            onChange={(status) => updateUserStatus(record.id, status)}
          />
        );

        // NOSONAR
        // if (searchedColumn === dataIndex) {
        //   if (record.status === text) {
        //     return <Tag color={status[record.status]}>{record.status}</Tag>;
        //   } else {
        //     return (
        //       <Highlighter
        //         highlightStyle={{
        //           backgroundColor: "#ffc069",
        //           padding: 0,
        //         }}
        //         searchWords={[searchText]}
        //         autoEscape
        //         textToHighlight={text ? text.toString() : ""}
        //       />
        //     );
        //   }
        // } else {
        //   if (record.status === text) {
        //     return <Tag color={status[record.status]}>{record.status}</Tag>;
        //   } else {
        //     return text;
        //   }
        // }
      },
    },
    {
      title: "",
      dataIndex: "details",
      key: "details",
      width: "5%",
      render: (_, record) => (
        <Space size="middle">
          {/* <Button type="link" className="p-0">
            Edit
          </Button> */}
          <Popconfirm title="Are you sure to delete this user?" onConfirm={() => deleteUser(record.id)} okText="Yes" cancelText="No">
            <Button type="link" className="p-0">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        size="large"
        title="Users"
        extra={
          <Button size="large" onClick={showModal} className="gradient-btn create-client-btn">
            Create New User
          </Button>
        }
        className="create-client-wrap "
      >
        <Table
          size="small"
          className="common-table"
          columns={columns}
          dataSource={usersData.users.map((user, key) => ({
            key,
            ...user,
          }))}
          scroll={{
            x: 500,
          }}
        />
      </Card>
      <Modal
        title="Create User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="create-client-modal"
        width={800}
        centered
        footer={
          <div className="modal-footer">
            <Button type="link" className="delete-btn" icon={<i className="icon-delete"></i>} onClick={clearFormFields}>
              Clear
            </Button>
            <div>
              <Button key="back" onClick={handleCancel} className="cancel-btn" size="large">
                Cancel
              </Button>
              <Button key="submit" type="primary" onClick={handleOk} className="add-btn" size="large">
                Add
              </Button>
            </div>
          </div>
        }
        closeIcon={<Button className="closeBtn" type="link" icon={<i className="icon-closeable"></i>} />}
      >
        <Form layout="vertical" form={formRef} onFinish={onFormFinish} onFinishFailed={onFormFinishFailed} autoComplete="off">
          <Row gutter={20}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="User Name:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                  {
                    validator: async (_, value) => maxLengthValidation(value, 100, "Username"),
                  },
                ]}
              >
                <Input placeholder="User Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Email ID:"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    validator: async (_, value) => maxLengthValidation(value, 100, "Email"),
                  },
                ]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Password: "
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    validator: async (_, value) => maxLengthValidation(value, 100, "Password"),
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                label="Confirm Password: "
                name=" confirmpassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please input your confirmation password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
