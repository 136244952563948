import NetworkCall from "network/networkCall";
import Request from "network/request";

export default class User {

  static async fetchUsers() {
    return await NetworkCall.fetch(Request.fetchUsers());
  }

  static async createNewUser(values) {
    return await NetworkCall.fetch(Request.createNewUser(values));
  }

  static async deleteUser(id) {
    return await NetworkCall.fetch(Request.deleteUser(id));
  }

  static async updateUserStatus(id, body) {
    return await NetworkCall.fetch(Request.updateUserStatus(id, body));
  }

}
