import { configureStore } from "@reduxjs/toolkit";
import authSlice from "redux/auth/authSlice";
import clientSlice from "redux/client/clientSlice";
import userSlice from "redux/user/userSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    clients: clientSlice,
    users: userSlice,
  },

  // everytime when we dispatch an action, this line will console 
  // the previous state, action with payload and updated state
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
