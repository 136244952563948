import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Form, Modal, Result, Spin, Typography, Upload, message } from "antd";
import Client from "models/client/client";
import { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { storeFileConfiguration } from "redux/client/clientSlice";
import { fileReader, filterSelectOptions } from "utilities/generalUtility";
import EFileMappingForms from "./eFileMappingForms";
import UploadEFileMappingsModal from "./uploadEFileMappingsModal";
import PropTypes from "prop-types";

const { Text } = Typography;

EFileMappings.propTypes = {
  id: PropTypes.number,
};

export default function EFileMappings({ id }) {
  // State variables
  const [eFileColumns, setEFileColumns] = useState([]);
  const [mappings, setMappings] = useState({ mappings: [] });
  const [uploadedFileNames, setUploadedFileNames] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedFilesHeaderData, setUploadedFilesHeaderData] = useState([]);
  const [columnOptions, setColumnOptions] = useState({});
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [uploadWarningModal, setUploadWarningModal] = useState(false);

  const [form] = Form.useForm();

  // Redux
  const fileConfiguration = useSelector((state) => state.clients.fileConfiguration);
  const dispatch = useDispatch();

  // Refs
  const allForms = useRef(null);
  const clickedUploadButton = useRef(null);

  const recentlyHoveredFileId = useRef(null);
  const recentlyHoveredFileColumnValue = useRef(null);
  const recentlyHoveredColumnValue = useRef(null);

  const setClickedUploadButton = (file) => {
    clickedUploadButton.current = {
      buttonId: file.id,
      buttonName: "upload" + file.id,
      prefixName: file.fileNamePrefix,
    };
  };

  const getMappings = async () => {
    try {
      const mappingsData = await Client.getMappings(id);
      setMappings(mappingsData);
    } catch (error) {
      console.error(error);
    }
  };

  const getEFileColumns = async () => {
    try {
      const availableOptions = await Client.getEFileColumns();

      // Sort required fields on top
      const sortedAvailableOptions = availableOptions.sort((a, b) => {
        if (a.type === "IR" && b.type !== "IR") {
          return -1;
        } else if (a.type !== "IR" && b.type === "IR") {
          return 1;
        }

        // NOSONAR
        // return a.type === "IR" ? -1 : b.type === "IR" ? 1 : 0;
      });
      setEFileColumns(sortedAvailableOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const getFileConfigurations = async () => {
    try {
      const data = await Client.getFileConfiguration(id, { type: "E" });
      // Maintain store if file configuration is updated
      const isUpdated = fileConfiguration?.isUpdated;
      if (data?.length > 0) await dispatch(storeFileConfiguration({ ...(isUpdated ? { isUpdated } : {}), files: data }));
    } catch (error) {
      dispatch(storeFileConfiguration(null));
    }
  };

  const createMappings = async (payload) => {
    try {
      const res = await Client.createMappings(payload);
      message.success(res.message);
    } catch (error) {
      message.error("Failed to create mappings");
    }
  };

  const updateMappings = async (payload) => {
    try {
      const res = await Client.updateMappings(payload);
      message.success(res.message);
    } catch (error) {
      message.error("Failed to update mappings");
    }
  };

  /* Fetch data and set initial default form values */
  const fetchData = () => {
    getMappings();
    getEFileColumns();
    getFileConfigurations();
  };

  const updateArrayToOptionsObj = (data, isSelected = false) => {
    return data?.map((header) => ({ value: header, isSelected }));
  };

  /**
   * ------------------------------------------------------------------------------------
   * AUTO POPULATE MAPPINGS
   * SET DEFAULT FORM VALUES AFTER UPLOADING ALL CONFIGURED FILES
   * mapping?.fileConfigurationsId = represents default uploaded file name
   * mapping?.eFileColumnsId = represents forms number
   * mapping?.fileColName = represents default header name, which is extracted from file
   * ------------------------------------------------------------------------------------
   */
  const setDefaultFormValues = (forms) => {
    let op = {};

    // Auto populate mappings, if mappings already exist
    // setTimeout(() => {
    eFileColumns?.forEach((file, index) => {
      mappings?.mappings?.forEach((mapping, i) => {
        if (file?.id === mapping?.eFileColumnsId) {
          let fileConfigId = mapping?.fileConfigurationsId;
          forms?.["form" + mapping?.eFileColumnsId]?.setFieldsValue({
            file: fileConfigId,
            column: mapping?.fileColName,
            ...(mapping?.valueMappings?.length !== 0 && {
              valueMappings: mapping?.valueMappings,
            }),
          });
        }
      });
      forms?.["form" + file.id]?.setFieldsValue({ eFileColumnsId: file.id });
    });
    // }, 3000);

    // In case of only one file configured, auto Populate eFileColumnName and its corresponding eFileColumnOptions in all forms
    if (fileConfiguration.files?.length === 1) {
      const fileConfigId = fileConfiguration.files[0].id;
      const headerRow = fileConfiguration.files[0].headerRow;
      let CSVHeaderRowData = uploadedFiles?.["upload" + fileConfigId]?.data[headerRow];
      eFileColumns.forEach((file, index) => {
        forms?.["form" + file?.id]?.setFieldsValue({ file: fileConfigId });
        op = { ...op, ["form" + file?.id]: updateArrayToOptionsObj(CSVHeaderRowData) };
      });
      setColumnOptions({ ...columnOptions, ...op });
    }

    // In case of multiple file configured, Iterate into file configurations, and auto add eFileColumn options but not populate them, against selected eFileColumnNames
    let formsNames = Object.keys(forms);
    fileConfiguration?.files.forEach((fileConfig) => {
      let defaultPopulatedColumns = [];
      let options = uploadedFilesHeaderData?.["upload" + fileConfig.id]?.data;
      let filteredOptions = [];
      /* Iterate into all the forms to get selected column values */
      formsNames.forEach((name, key) => {
        if (key !== 0) {
          let formValues = forms?.[name].getFieldsValue();
          if (formValues?.file === fileConfig.id && formValues?.column?.length > 0) {
            defaultPopulatedColumns.push(formValues?.column);
          }
        }
      });

      // Filter column options
      filteredOptions =
        options
          ?.filter((val) => !defaultPopulatedColumns.includes(val))
          .concat(defaultPopulatedColumns.filter((x) => !options.includes(x))) ?? [];

      /* Iterate into all the forms to add filtered column options */
      formsNames.forEach((name, key) => {
        if (key !== 0) {
          let formValues = forms?.[name].getFieldsValue();

          // NOSONAR
          // let newOptions = null;
          if (formValues?.file === fileConfig.id) {
            // Filter column options
            // newOptions = [formValues?.column, ...filteredOptions];

            // Default options
            // newOptions = options;

            op = {
              ...op,
              [name]: [...updateArrayToOptionsObj(defaultPopulatedColumns, true), ...updateArrayToOptionsObj(filteredOptions)],
            };
          }
        }
      });
    });
    setColumnOptions({ ...columnOptions, ...op });
  };

  /* FORM SUBMIT */
  const submitFormData = async (forms) => {
    // Will return values with status
    let allSettledPromises = null;
    allSettledPromises = await Promise.allSettled(
      Object.keys(forms).map(async (item) => {
        let validatedData = await forms[item].validateFields();
        return validatedData;
      }),
    );

    // Filter fulfilled status values and save in data: array[]
    let fulfilledData = [];
    let rejectedData = [];
    allSettledPromises.forEach((result) => {
      if (result.status === "fulfilled") fulfilledData.push(result.value);
      if (result.status === "rejected") rejectedData.push(result.value);
    });

    /* Create payload */
    let objArray = [];
    fulfilledData.forEach((data, key) => {
      if (key !== 0) {
        let valueMappings = data?.valueMappings === undefined ? [] : data?.valueMappings;
        let obj = {
          fileConfigurationsId: data.file,
          fileColName: data.column,
          eFileColumnsId: data.eFileColumnsId,
          valueMappings: valueMappings.map((mapping) => ({
            originalValue:
              mapping.originalValue === undefined || mapping.originalValue === "" ? null : mapping.originalValue?.toString(),
            mappedValue: mapping.mappedValue === undefined || mapping.mappedValue === "" ? null : mapping.mappedValue?.toString(),
          })),
        };
        if (
          data.file !== null &&
          data.file !== undefined &&
          data.file?.length !== 0 &&
          data.column !== null &&
          data.column !== undefined &&
          data.column?.length !== 0
        )
          objArray.push(obj);
      }
    });
    let payload = { clientId: id, mappings: objArray };
    setMappings(payload);

    /* Create/Update Mappings */
    if (rejectedData.length === 0) {
      mappings?.mappings?.length === 0 ? createMappings(payload) : updateMappings(payload);
    } else {
      message.error("Required fields are missing");
    }
    setOnSubmitLoader(false);
  };

  /**
   * -----------------------------------------------------------------------------
   * Fetch data from uploaded file then populate in selected column field dropdown
   * -----------------------------------------------------------------------------
   */
  const handleFileSelection = (fileConfigId, formName, formId) => {
    const isFileConfigurationUpdated = !fileConfiguration?.isUpdated;

    // Find the index of an object in an array, by a specific property
    let index = fileConfiguration?.files.findIndex((file) => file.id === fileConfigId);

    // On file clear, remove perspective coloum value and its options
    if (fileConfigId === undefined && isFileConfigurationUpdated) {
      // NOSONAR
      // const recentFilteredColumns = filterColumns(null, formName, recentlyHoveredFileId.current);
      let recentHighlightedColumns = highlightColumnsByFileChanging(fileConfigId, formName);

      allForms.current?.[formName].setFieldsValue({ column: undefined, valueMappings: [] });
      setColumnOptions({ ...columnOptions, /* ...recentFilteredColumns,*/ ...recentHighlightedColumns, [formName]: [] });
    }

    // Filter column options by changing file
    // if (recentlyHoveredFileId.current !== undefined && fileConfigId !== recentlyHoveredFileId.current && fileConfigId !== undefined) {
    //   let recentFilteredColumns = filterColumns(null, formName, recentlyHoveredFileId.current);
    //   let filteredColumns = filterColumns(null, formName, fileConfigId);
    //   setColumnOptions({ ...columnOptions, ...recentFilteredColumns, ...filteredColumns });
    //   allForms.current?.[formName].setFieldsValue({ column: undefined });
    // } else

    if (
      // recentlyHoveredFileId.current === undefined &&
      uploadedFileNames?.["upload" + fileConfigId] !== undefined
    ) {
      // Fetch header row from file configuration */
      let headerRow = fileConfiguration?.files[index]?.headerRow;

      // Fetch data from uplaoded file */
      let CSVHeaderRowData = uploadedFiles?.["upload" + fileConfigId]?.data[headerRow];
      if (CSVHeaderRowData !== undefined) {
        // First empty then add selected column options
        if (isFileConfigurationUpdated) allForms.current?.[formName].setFieldsValue({ column: undefined });

        // Filter column options on file changing
        // let filteredColumns = filterColumnsByFile(fileConfigId, CSVHeaderRowData, formName);
        // setColumnOptions({ ...columnOptions, ...filteredColumns });

        // Comment below lines if, Filter column options on file changing
        CSVHeaderRowData = updateArrayToOptionsObj(CSVHeaderRowData);
        let recentHighlightedColumns = highlightColumnsByFileChanging(fileConfigId, formName, CSVHeaderRowData);
        setColumnOptions({ ...columnOptions, /*[formName]: CSVHeaderRowData,*/ ...recentHighlightedColumns });
      } else {
        message.error(`Data isn't existed in ${headerRow} number row of ${fileConfiguration?.files[index]?.fileNamePrefix} file`);
        /* Empty column dropdown */
        setColumnOptions({ ...columnOptions, [formName]: [] });
      }
    }
  };

  /**
   * --------------------------------------
   * Filter Column Options By Changing File
   * --------------------------------------
   */
  // NOSONAR
  // const filterColumnsByFile = (fileConfigId, options, formName) => {
  //   // valuesToFilter variable contains already populated column against selected file
  //   let valuesToFilter = [];
  //   let filter = false;
  //   const formsNames = Object.keys(allForms.current);

  // NOSONAR
  //   // Get the values to filter
  //   formsNames.forEach((name, key) => {
  //     if (key !== 0) {
  //       let formValues = allForms.current?.[name].getFieldsValue();
  //       if (formValues?.file === fileConfigId && formValues?.column?.length > 0) {
  //         filter = true;
  //         valuesToFilter.push(formValues?.column);
  //       }
  //     }
  //   });

  // NOSONAR
  //   // Values after filtering
  //   let filteredValues = null;
  //   if (filter === true) {
  //     filteredValues = options.filter((Value) => !valuesToFilter.includes(Value));
  //     filteredValues = updateArrayToOptionsObj(filteredValues);

  //     return { [formName]: filteredValues };
  //   } else {
  //     options = updateArrayToOptionsObj(options);
  //     return { [formName]: options };
  //   }
  // };

  /**
   * -----------------------------------------
   * Highlight Column Options By Changing File
   * -----------------------------------------
   */
  const highlightColumnsByFileChanging = (selectedFileId, formName, CSVHeaderRowData = []) => {
    const previousColumnValue = recentlyHoveredFileColumnValue.current;
    const previousFileId = recentlyHoveredFileId.current;
    let isPreviousColumnValueExisted = false;
    const formsNames = Object.keys(allForms.current);

    // Check if previous(cleared) column option is already selected in any other form
    formsNames.forEach((form) => {
      const formInstance = allForms.current?.[form]?.getFieldsValue();
      if (
        recentlyHoveredFileId.current === formInstance?.file && // check the same files, which is cleared
        recentlyHoveredFileColumnValue.current !== undefined && // must column is not empty
        recentlyHoveredFileColumnValue.current === formInstance?.column // iterating through forms
      ) {
        isPreviousColumnValueExisted = true;
      }
    });

    // --------On first time file selection--------
    if (previousFileId === undefined) {
      let formsWithSameFileSelected = [];
      Object.keys(columnOptions).forEach((form) => {
        // Highlight the column options of the same selected files
        if (selectedFileId === allForms.current?.[form]?.getFieldsValue()?.file) {
          // When option is changed, then remove previous value highlighter
          formsWithSameFileSelected.push(form);
        }
      });

      formsWithSameFileSelected = formsWithSameFileSelected.filter((form) => form !== formName);
      if (formsWithSameFileSelected.length > 0) {
        return { [formName]: columnOptions?.[formsWithSameFileSelected[0]] };
      }
      return { [formName]: CSVHeaderRowData };

      // -------- On file changing --------
    } else if (previousFileId !== undefined && selectedFileId !== undefined) {
      // Highlight selected column from all options on clear file
      let highlightedOptions = {};
      Object.keys(columnOptions).forEach((form) => {
        // Highlight the column options of the same selected files
        if (previousFileId === allForms.current?.[form]?.getFieldsValue()?.file) {
          // When option is changed, then remove previous value highlighter
          let newHighlightedOptions = columnOptions[form].map((option) => {
            const columnValue = option.value;
            if (previousColumnValue === columnValue && !isPreviousColumnValueExisted) return { value: columnValue, isSelected: false };
            else return { value: columnValue, isSelected: option.isSelected };
          });

          highlightedOptions = { ...highlightedOptions, [form]: newHighlightedOptions };
        }
      });

      let formsWithSameFileSelected = [];
      Object.keys(columnOptions).forEach((form) => {
        // Highlight the column options of the same selected files
        if (selectedFileId === allForms.current?.[form]?.getFieldsValue()?.file) {
          // When option is changed, then remove previous value highlighter
          formsWithSameFileSelected.push(form);
        }
      });

      formsWithSameFileSelected = formsWithSameFileSelected.filter((form) => form !== formName);
      if (formsWithSameFileSelected.length > 0) {
        return { ...highlightedOptions, [formName]: columnOptions?.[formsWithSameFileSelected[0]] };
      }
      return { ...highlightedOptions, [formName]: CSVHeaderRowData };

      // -------- On file clearing --------
    } else {
      // Highlight selected column from all options on clear file
      let highlightedOptions = {};
      Object.keys(columnOptions).forEach((form) => {
        // Highlight the column options of the same selected files
        if (previousFileId === allForms.current?.[form]?.getFieldsValue()?.file) {
          // When option is changed, then remove previous value highlighter
          let newHighlightedOptions = columnOptions[form].map((option) => {
            const columnValue = option.value;
            if (previousColumnValue === columnValue && !isPreviousColumnValueExisted) return { value: columnValue, isSelected: false };
            else return { value: columnValue, isSelected: option.isSelected };
          });

          highlightedOptions = { ...highlightedOptions, [form]: newHighlightedOptions };
        }
      });
      return highlightedOptions;
    }
  };

  const getCSVHeaderRowData = (fileConfigId) => {
    // Find the index of an object in an array, by a specific property
    let index = fileConfiguration?.files?.findIndex((file) => file.id === fileConfigId);

    // Fetch header row from file configuration */
    let headerRow = fileConfiguration?.files[index]?.headerRow;

    // Fetch data from uplaoded file */
    let CSVHeaderRowData = uploadedFiles?.["upload" + fileConfigId]?.data[headerRow];

    // Filter the 0th and headerRow index arrays
    const CSVData = uploadedFiles?.["upload" + fileConfigId]?.data;
    const CSVColumns = CSVData?.filter((_, index) => index !== 0 && index !== headerRow);

    return { CSVHeaderRowData, CSVColumns };
  };

  /**
   * ------------------------------------------------------------
   * Populate Replace Value in Mappings By Changing Column Option
   * ------------------------------------------------------------
   */
  const getUniqueCSVColumns = (fileConfigId, selectedOption) => {
    const { CSVHeaderRowData, CSVColumns } = getCSVHeaderRowData(fileConfigId);

    // Identify column index in csv file of selected option
    const selectedOptionColumnIndex = CSVHeaderRowData?.findIndex((data) => data === selectedOption);

    // Get the specific index value of each nested array
    const CSVColumn = CSVColumns?.map((innerArr) => innerArr[selectedOptionColumnIndex]);
    const uniqueCSVColumn = [...new Set(CSVColumn)]?.filter((value) => value !== undefined);

    // Set structured for AutoComplete Options
    return uniqueCSVColumn?.map((column) => ({ value: column?.toString(), label: column?.toString() }));
  };

  /**
   * ----------------------
   * Filter Column Options
   * ----------------------
   */
  // NOSONAR
  // const filterColumns = (columnValue, formName, selectedColumnFileId) => {
  //   if (selectedColumnFileId === null) {
  //     selectedColumnFileId = allForms.current?.[formName]?.getFieldsValue()?.file;
  //   }

  // NOSONAR
  //   // Get all options from uploaded file headers
  //   const allOptions = uploadedFilesHeaderData?.["upload" + selectedColumnFileId]?.data;

  // NOSONAR
  //   // Filter selected column from all options
  //   const filteredOptions = allOptions.filter((option) => option !== columnValue);

  // NOSONAR
  //   // Convert object to array for iterating
  //   const formsNames = Object.keys(allForms.current);

  // NOSONAR
  //   // Get the populated column options from all forms of currently selected column to filter
  //   let populatedColumns = [];
  //   formsNames.forEach((name, key) => {
  //     if (key !== 0) {
  //       let formValues = allForms.current?.[name].getFieldsValue();
  //       if (formValues?.file === selectedColumnFileId && formValues?.column?.length > 0) {
  //         populatedColumns.push(formValues?.column);
  //       }
  //     }
  //   });

  // NOSONAR
  //   // Further filter all populated column options from already filtered column options, so that we can get remaining non populated column options
  //   let nonPopulatedColumns = filteredOptions.filter((val) => !populatedColumns.includes(val));

  // NOSONAR
  //   // Populate filtered non populated column options to all not selected column options
  //   let filteredColumns = {};
  //   formsNames.forEach((name, key) => {
  //     if (key !== 0) {
  //       let formValues = allForms.current?.[name].getFieldsValue();
  //       let newOptions = [];

  //       // where the same files are selected
  //       if (formValues?.file === selectedColumnFileId && formValues?.column?.length > 0) {
  //         newOptions = [formValues?.column, ...nonPopulatedColumns];
  //         newOptions = updateArrayToOptionsObj(newOptions);
  //         filteredColumns = { ...filteredColumns, [name]: newOptions };
  //       } else if (formValues?.file === selectedColumnFileId) {
  //         nonPopulatedColumns = updateArrayToOptionsObj(nonPopulatedColumns);
  //         filteredColumns = { ...filteredColumns, [name]: nonPopulatedColumns };
  //       }
  //     }
  //   });
  //   return filteredColumns;
  // };

  /**
   * ------------------------
   * Highlight Column Options
   * ------------------------
   */
  const highlightColumns = (selectedColumnValue, formName, selectedColumnFileId) => {
    const previousColumnValue = recentlyHoveredColumnValue.current;
    if (selectedColumnFileId === null) {
      selectedColumnFileId = allForms.current?.[formName]?.getFieldsValue()?.file;
    }

    let isPreviousColumnValueExisted = false;
    const formsNames = Object.keys(allForms.current);

    // Check if previous(cleared) column option is already selected in any other form
    formsNames.forEach((form) => {
      const formInstance = allForms.current?.[form]?.getFieldsValue();
      if (
        selectedColumnFileId === formInstance?.file && // is same file
        previousColumnValue !== undefined && // must column is changed
        previousColumnValue === formInstance?.column // iterating through forms
      ) {
        isPreviousColumnValueExisted = true;
      }
    });

    // Highlight selected column from all options
    let highlightedOptions = {};
    Object.keys(columnOptions).forEach((form) => {
      // Highlight the column options of the same selected files
      if (selectedColumnFileId === allForms.current?.[form]?.getFieldsValue()?.file) {
        let newHighlightedOptions = columnOptions[form].map((option) => {
          const columnValue = option?.value;

          // On first time column selection
          if (selectedColumnValue === columnValue) return { value: columnValue, isSelected: true };

          // When column option is cleared
          if (selectedColumnValue === undefined && previousColumnValue === columnValue && !isPreviousColumnValueExisted)
            return { value: columnValue, isSelected: false };

          return { value: columnValue, isSelected: option.isSelected };
        });

        // When option is changed, then remove previous value highlighter
        newHighlightedOptions = newHighlightedOptions.map((option) => {
          const columnValue = option.value;
          if (previousColumnValue === columnValue && !isPreviousColumnValueExisted) return { value: columnValue, isSelected: false };
          else return { value: columnValue, isSelected: option.isSelected };
        });

        highlightedOptions = { ...highlightedOptions, [form]: newHighlightedOptions };
      }
    });

    return highlightedOptions;
  };

  const setDefaultOriginalMappingValues = (mappings) => {
    // Iterate through all forms to set original mapping values on the basis of selected option
    const updatedEfileColumns = eFileColumns.map((file, key) => {
      let autoCompleteTagOptions = [];

      // iterate through mappings to get the fileConfigurationId
      mappings?.mappings?.forEach((mapping, i) => {
        if (file?.id === mapping?.eFileColumnsId && file.mappingAllowed === true) {
          let fileConfigId = mapping?.fileConfigurationsId;
          autoCompleteTagOptions = getUniqueCSVColumns(fileConfigId, mapping?.fileColName);
        }
      });

      // Populate replace value in mappings by selected option
      return { ...eFileColumns[key], availableOriginalOptions: autoCompleteTagOptions };
    });

    setEFileColumns(updatedEfileColumns);
  };

  useEffect(() => {
    if (eFileColumns.length === 0) {
      fetchData();
    }

    if (loader === true) {
      setAllFilesUploaded(true);
      form.submit();
      setLoader(false);
    }
  }, [eFileColumns, loader]);

  useEffect(() => {
    if (Object.keys(uploadedFiles).length >= fileConfiguration?.files.length) {
      setLoader(true);
      setDefaultOriginalMappingValues(mappings);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (onSubmitLoader === true) {
      submitFormData(allForms.current);
    }
  }, [onSubmitLoader]);

  useEffect(() => {
    if (eFileColumns.length > 0) form.submit();
  }, [eFileColumns, mappings]);

  const resetAllForms = () => {
    const formsNames = Object.keys(allForms.current);
    let options = {};

    formsNames.forEach((name, key) => {
      if (key !== 0) {
        allForms.current?.[name].setFieldsValue({
          file: undefined,
          column: undefined,
          valueMappings: [],
        });
        options[name] = [];
      }
    });

    setColumnOptions((prevColumnOptions) => ({
      ...prevColumnOptions,
      ...options,
    }));
  };

  const validateFile = (file, clickedBtn) => {
    if (file.type !== "text/csv") {
      message.error(`${file.name} is not a CSV file`);
      return false;
    }

    const fileName = file.name.toLowerCase();
    const buttonName = clickedBtn.prefixName.toLowerCase();

    if (!fileName.startsWith(buttonName)) {
      message.error(`${file.name} prefix does not match ${clickedBtn.prefixName}`);
      return false;
    }

    return true;
  };

  const processUploadedFile = async (file, clickedBtn, fileConfiguration) => {
    // Close the reupload file modal if uploaded file is error-free
    setUploadWarningModal(false);

    setUploadedFileNames({
      ...uploadedFileNames,
      [clickedUploadButton.current.buttonName]: file,
    });

    try {
      const res = await trackPromise(fileReader(file));
      const index = fileConfiguration?.files.findIndex((file) => file.id === clickedBtn.buttonId);

      // Fetch the header row from file configuration
      const headerRow = fileConfiguration?.files[index].headerRow;
      const obj = { [clickedBtn.buttonName]: res };

      // Update the uploaded files with their content
      setUploadedFiles({ ...uploadedFiles, ...obj });

      // Update the header data of the uploaded file
      const obj2 = { [clickedBtn.buttonName]: { data: res.data[headerRow] } };
      setUploadedFilesHeaderData({ ...uploadedFilesHeaderData, ...obj2 });

      // Check if all files have been uploaded, then reset forms
      if (Object.keys(uploadedFiles).length >= fileConfiguration?.files.length) {
        resetAllForms();
      }
    } catch (error) {
      message.error(error);
    }
  };

  const readFiles = async (file, clickedBtn) => {
    if (validateFile(file, clickedBtn)) {
      await processUploadedFile(file, clickedBtn, fileConfiguration);
    }
  };

  /* PROPS FOR ANTD UPLOAD COMPONENT */
  const props = {
    maxCount: 1,
    multiple: false,
    action: "",
    showUploadList: false,
    headers: { authorization: "authorization-text" },
    beforeUpload: (file) => {
      readFiles(file, clickedUploadButton.current);
      // To prevent send network call
      return false;
    },
  };

  return (
    <>
      {fileConfiguration !== null ? (
        <Form.Provider
          onFormChange={(formName, { changedFields, forms }) => {
            allForms.current = forms;
          }}
          onFormFinish={(name, { values, forms }) => {
            setDefaultFormValues(forms);
          }}
        >
          <Form className="mapping-form" form={form} encType="multipart/form-data">
            {/* File uploads form */}
            <Form name="uploads" encType="multipart/form-data" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {fileConfiguration?.files.map((fileConfig, index) => (
                  <div className="upload-btn-with-label" key={index + 1}>
                    {Object.keys(uploadedFiles).length >= fileConfiguration?.files.length ? (
                      <div>
                        <Button
                          type="primary"
                          className="upload-btn mb-4"
                          onClick={() => {
                            setUploadWarningModal(true);
                            setClickedUploadButton(fileConfig);
                          }}
                        >
                          Upload
                        </Button>
                        <Modal
                          title={
                            <Text strong>
                              <ExclamationCircleFilled style={{ color: "#faad14" }} /> Warning
                            </Text>
                          }
                          open={uploadWarningModal}
                          footer={[
                            <Form.Item name={"upload" + fileConfig.id} key={1} noStyle>
                              <Upload {...props} name="uploadedFile">
                                <Button type="primary" className="upload-btn">
                                  Re Upload
                                </Button>
                              </Upload>
                            </Form.Item>,
                          ]}
                          onCancel={() => setUploadWarningModal(false)}
                        >
                          <p>File re uploading will remove all the mappings!</p>
                        </Modal>
                      </div>
                    ) : (
                      <Form.Item name={"upload" + fileConfig.id}>
                        <Upload {...props} name="uploadedFile" onClick={() => setClickedUploadButton(fileConfig)}>
                          <Button type="primary" className="upload-btn">
                            Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}

                    <label className="upload-btn-label">
                      File Name ({fileConfig.fileNamePrefix}):{" "}
                      <span className="upload-btn-span">{uploadedFileNames?.["upload" + fileConfig.id]?.name}</span>
                    </label>
                  </div>
                ))}
              </div>

              <UploadEFileMappingsModal
                allForms={allForms}
                fileConfiguration={fileConfiguration}
                clientId={id}
                eFileColumns={eFileColumns}
                setMappings={setMappings}
                setDefaultOriginalMappingValues={setDefaultOriginalMappingValues}
              />
            </Form>

            {onSubmitLoader ? <Spin size="large" /> : ""}

            {/* Visible forms only if mappings exists and all files are uploaded  */}
            {(mappings?.mappings?.length !== 0 || allFilesUploaded) && (
              <EFileMappingForms
                eFileColumns={eFileColumns}
                setEFileColumns={setEFileColumns}
                disabled={!allFilesUploaded}
                filterSelectOptions={filterSelectOptions}
                handleFileSelection={handleFileSelection}
                recentlyHoveredFileId={recentlyHoveredFileId}
                fileConfiguration={fileConfiguration}
                // filterColumns={filterColumns}
                highlightColumns={highlightColumns}
                setColumnOptions={setColumnOptions}
                recentlyHoveredFileColumnValue={recentlyHoveredFileColumnValue}
                recentlyHoveredColumnValue={recentlyHoveredColumnValue}
                getUniqueCSVColumns={getUniqueCSVColumns}
                allForms={allForms}
                columnOptions={columnOptions}
                form={form}
                allFilesUploaded={allFilesUploaded}
                mappings={mappings}
                setLoader={setLoader}
              />
            )}
            <Form.Item className="client-details-btn mb-0">
              <Button className="gradient-btn" size="large" type="primary" onClick={() => setOnSubmitLoader(true)}>
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Form.Provider>
      ) : (
        <div>
          <Result className="mapping-result" title="Please complete File Configuration before proceeding with Mapping." />
        </div>
      )}
    </>
  );
}
