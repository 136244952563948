import K from "utilities/constants";
import AuthUser from "models/auth/auth";

export default class Request {
  constructor(
    relativeURL,
    method = K.Network.Method.GET,
    body = null,
    defaultHeaderType = K.Network.Header.Type.Json,
    headers = {}
  ) {
    const token = AuthUser.getToken();
    headers = {
      ...(defaultHeaderType === K.Network.Header.Type.Json
        ? K.Network.Header.Default(token)
        : K.Network.Header.Authorization(token)),
      ...headers,
    };
    this.url = K.Network.URL.BaseAPI + relativeURL;
    this.method = method;
    this.body = body;
    this.headers = headers;
  }

  // Auth calls.
  static loginUser(email, password) {
    const body = {
      email,
      password,
    };
    return new Request(
      K.Network.URL.LoginUser,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static forgotPassword(email) {
    const body = {
      email,
    };
    return new Request(
      K.Network.URL.ForgotPassword,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static resetPassword(password, token) {
    const body = {
      password,
      token,
    };
    return new Request(
      K.Network.URL.ResetPassword,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  /**
   * -------------
   * Client Calls
   * -------------
   */
  static fetchClients() {
    const body = {};
    return new Request(
      K.Network.URL.FetchClients,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createNewClient(values) {
    const body = values;
    return new Request(
      K.Network.URL.CreateNewClient,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getClientDetails(id) {
    const body = {};
    return new Request(
      K.Network.URL.GetClientDetails + id,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static updateClient(values, id) {
    const body = values;
    return new Request(
      K.Network.URL.UpdateClient + id,
      K.Network.Method.PUT,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static deleteClient(id) {
    const body = {};
    return new Request(
      K.Network.URL.DeleteClient + "/" + id,
      K.Network.Method.DELETE,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getFileConfigurations(id) {
    const body = {};
    return new Request(
      K.Network.URL.GetFileConfigurations + id,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getFileConfiguration(id, body) {
    return new Request(
      K.Network.URL.GetFileConfiguration + id,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createFileConfigurations(values) {
    const body = values;
    return new Request(
      K.Network.URL.CreateFileConfigurations,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static updateFileConfigurations(values) {
    const body = values;
    return new Request(
      K.Network.URL.UpdateFileConfigurations,
      K.Network.Method.PATCH,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getEFileColumns() {
    const body = {};
    return new Request(
      K.Network.URL.GetEFileColumns,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getMappings(id) {
    const body = {};
    return new Request(
      K.Network.URL.GetMappings + id,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createMappings(values) {
    const body = values;
    return new Request(
      K.Network.URL.CreateMappings,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static updateMappings(values) {
    const body = values;
    return new Request(
      K.Network.URL.UpdateMappings,
      K.Network.Method.PATCH,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getFFileColumns() {
    const body = {};
    return new Request(
      K.Network.URL.GetFFileColumns,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getFFileMappings(id) {
    const body = {};
    return new Request(
      K.Network.URL.GetFFileMappings + id,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createFFileMappings(values) {
    const body = values;
    return new Request(
      K.Network.URL.CreateFFileMappings,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static updateFFileMappings(values) {
    const body = values;
    return new Request(
      K.Network.URL.UpdateFFileMappings,
      K.Network.Method.PATCH,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getOFileColumns() {
    const body = {};
    return new Request(
      K.Network.URL.GetOFileColumns,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getOFileMappings(id) {
    const body = {};
    return new Request(
      K.Network.URL.GetOFileMappings + id,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createOFileMappings(values) {
    const body = values;
    return new Request(
      K.Network.URL.CreateOFileMappings,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static updateOFileMappings(values) {
    const body = values;
    return new Request(
      K.Network.URL.UpdateOFileMappings,
      K.Network.Method.PATCH,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getEFileJobs(id, body) {
    return new Request(
      K.Network.URL.GetEFileJobs + id,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getEFileJobsStats(id) {
    return new Request(
      K.Network.URL.GetEFileJobsStats + id,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getFFileJobs(id, body) {
    return new Request(
      K.Network.URL.GetFFileJobs + id,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getFFileJobsStats(id) {
    return new Request(
      K.Network.URL.GetFFileJobsStats + id,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getOFileJobs(id, body) {
    return new Request(
      K.Network.URL.GetOFileJobs + id,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static getOFileJobsStats(id) {
    return new Request(
      K.Network.URL.GetOFileJobsStats + id,
      K.Network.Method.GET,
      null,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createEeidMappings(body) {
    return new Request(
      K.Network.URL.CreateEeidMappings,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  /**
   * -----------
   * User Calls
   * -----------
   */
   static fetchUsers() {
    const body = {};
    return new Request(
      K.Network.URL.FetchUsers,
      K.Network.Method.GET,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static createNewUser(values) {
    const body = values;
    return new Request(
      K.Network.URL.CreateNewUser,
      K.Network.Method.POST,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static deleteUser(id) {
    const body = {};
    return new Request(
      K.Network.URL.DeleteUser + "/" + id,
      K.Network.Method.DELETE,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

  static updateUserStatus(id, body) {
    return new Request(
      K.Network.URL.UpdateUserStatus + "/" + id,
      K.Network.Method.PATCH,
      body,
      K.Network.Header.Type.Json,
      {}
    );
  }

}
