import NetworkCall from "network/networkCall";
import Request from "network/request";

export default class Client {

  static async fetchClients() {
    return await NetworkCall.fetch(Request.fetchClients());
  }

  static async createNewClient(values) {
    return await NetworkCall.fetch(Request.createNewClient(values));
  }

  static async getClientDetails(id) {
    return await NetworkCall.fetch(Request.getClientDetails(id));
  }

  static async updateClient(values, id) {
    return await NetworkCall.fetch(Request.updateClient(values, id));
  }

  static async deleteClient(id) {
    return await NetworkCall.fetch(Request.deleteClient(id));
  }

  static async getFileConfigurations(id) {
    return await NetworkCall.fetch(Request.getFileConfigurations(id));
  }

  static async getFileConfiguration(id, body) {
    return await NetworkCall.fetch(Request.getFileConfiguration(id, body));
  }

  static async createFileConfigurations(values) {
    return await NetworkCall.fetch(Request.createFileConfigurations(values));
  }

  static async updateFileConfigurations(values) {
    return await NetworkCall.fetch(Request.updateFileConfigurations(values));
  }

  static async getEFileColumns() {
    return await NetworkCall.fetch(Request.getEFileColumns());
  }

  static async getMappings(id) {
    return await NetworkCall.fetch(Request.getMappings(id));
  }

  static async createMappings(values) {
    return await NetworkCall.fetch(Request.createMappings(values));
  }

  static async updateMappings(values) {
    return await NetworkCall.fetch(Request.updateMappings(values));
  }

  static async getFFileColumns() {
    return await NetworkCall.fetch(Request.getFFileColumns());
  }

  static async getFFileMappings(id) {
    return await NetworkCall.fetch(Request.getFFileMappings(id));
  }

  static async createFFileMappings(values) {
    return await NetworkCall.fetch(Request.createFFileMappings(values));
  }

  static async updateFFileMappings(values) {
    return await NetworkCall.fetch(Request.updateFFileMappings(values));
  }

  static async getOFileColumns() {
    return await NetworkCall.fetch(Request.getOFileColumns());
  }

  static async getOFileMappings(id) {
    return await NetworkCall.fetch(Request.getOFileMappings(id));
  }

  static async createOFileMappings(values) {
    return await NetworkCall.fetch(Request.createOFileMappings(values));
  }

  static async updateOFileMappings(values) {
    return await NetworkCall.fetch(Request.updateOFileMappings(values));
  }

  static async getEFileJobs(id, body) {
    return await NetworkCall.fetch(Request.getEFileJobs(id, body));
  }

  static async getEFileJobsStats(id) {
    return await NetworkCall.fetch(Request.getEFileJobsStats(id));
  }

  static async getFFileJobs(id, body) {
    return await NetworkCall.fetch(Request.getFFileJobs(id, body));
  }

  static async getFFileJobsStats(id) {
    return await NetworkCall.fetch(Request.getFFileJobsStats(id));
  }

  static async getOFileJobs(id, body) {
    return await NetworkCall.fetch(Request.getOFileJobs(id, body));
  }

  static async getOFileJobsStats(id) {
    return await NetworkCall.fetch(Request.getOFileJobsStats(id));
  }

  static async createEeidMappings(payload) {
    return await NetworkCall.fetch(Request.createEeidMappings(payload));
  }

}
