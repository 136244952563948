import Login from "features/login/login";
import Clients from "features/clients/clients";
import NotFound from "features/notFound/notFound";
import Unauthorized from "features/unauthorized/unauthorized";
import Users from "features/users/users";
import GuestPageLayout from "layout/guestPageLayout";
import LoggedInPageLayout from "layout/loggedInPageLayout";
import ClientDetailsInfo from "features/clientDetails/clientDetailsInfo";

// Template for a route
// {
//   path: '/login',
//   name: "Login",
//   component: Login,
//   authenticated: false,
//   roles: [],
//   children: [],
//   exact: true,
//   layout: LoggedInPageLayout
// },

const defaultCrudChildren = [
  { path: "/details/:id", authenticated: true, name: "Details" },
  { path: "/store/:id", authenticated: true, name: "Edit" },
];

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: GuestPageLayout,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: Register,
  //   layout: GuestPageLayout,
  // },
  // {
  //   path: "/forgot-password",
  //   name: "ForgotPassword",
  //   authenticated: true,
  //   component: ForgotPassword,
  //   layout: GuestPageLayout,
  // },
  // {
  //   path: "/set-password",
  //   name: "SetPassword",
  //   authenticated: true,
  //   component: SetPassword,
  //   layout: GuestPageLayout,
  // },
  {
    path: "/",
    name: "Clients",
    exact: true,
    authenticated: true,
    component: Clients,
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients",
    name: "Clients",
    exact: true,
    authenticated: true,
    component: Clients,
    layout: LoggedInPageLayout,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    authenticated: true,
    exact: true,
    roles: [],
    children: defaultCrudChildren,
    layout: LoggedInPageLayout,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    authenticated: true,
    roles: [],
    layout: GuestPageLayout,
  },
  {
    path: "/clients/details/:id",
    name: "ClientDetails",
    component: ClientDetailsInfo,
    authenticated: true,
    layout: LoggedInPageLayout,
  },
  // {
  //   path: "/profile-management",
  //   name: "ProfileManagement",
  //   exact: true,
  //   component: ProfileManagement,
  //   authenticated: true,
  //   layout: LoggedInPageLayout,
  // },

  {
    path: "*",
    name: "Not Found",
    authenticated: true,
    component: NotFound,
    layout: GuestPageLayout,
  },
];

export default routes;
