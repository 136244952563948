import { message } from "antd";
import K from "./constants";
import history from "./history";
import { read, utils } from "xlsx";

/* XLSX file config */
const { decode_range, encode_col, sheet_to_json } = utils;
const make_cols = (refstr) =>
  Array.from({ length: decode_range(refstr).e.c + 1 }, (_, i) => ({
    name: encode_col(i),
    key: i,
  }));

export const fileReader = (file) => {
  return new Promise((resolve, reject) => {
    try {
      /* File Reader */
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result;
        const wb = read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = sheet_to_json(ws, { header: 1 });
        data.unshift([]);
        /* Extract column Names */
        let cols = make_cols(ws["!ref"]);
        resolve({ data, cols });
      };
    } catch (error) {
      reject("File Reading Failed");
    }
  });
};

export const fileSheetReader = (file, sheetName) => {
  return new Promise((resolve, reject) => {
    try {
      /* File Reader */
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result;
        const wb = read(ab, { type: "array" });
        /* Get data by sheet name */
        const ws = wb.Sheets[sheetName];
        if (ws === undefined) {
          reject(`${sheetName} sheet is not existed in ${file.name} file`);
        }
        /* Convert array of arrays */
        const data = sheet_to_json(ws, { header: 1 });
        resolve(data);
      };
    } catch (error) {
      reject("File Reading Failed");
    }
  });
};

/**
 * Validate excel data for file configuration
 */
export const validateFileConfigExcelData = (data) => {
  const errors = [];

  // Check if the data is empty
  if (data.length === 0 || data[0].length === 0) {
    errors.push("File should not be empty");
    return errors;
  }

  // Extract header and rows
  const headers = data[0];
  const rows = data.slice(1);

  const expectedHeaders = [
    "RepeatType",
    "RepeatDay",
    "RepeatFrequency",
    "MonthlyDate",
    "BiMonthlyDateOne",
    "BiMonthlyDateTwo",
    "HeaderRowNumber",
    "FileNamePrefix",
    "EmployeeIdColumnName",
    "Type",
  ];

  // Check for missing headers
  expectedHeaders.forEach((header) => {
    if (!headers.includes(header)) {
      errors.push(`${header} column is missing`);
    }
  });

  // Check if there is only one row and it's the header
  if (rows.length === 0) {
    errors.push("There is no data present in uploaded file");
    return errors;
  }

  // Initialize variable to track RepeatType consistency
  let repeatTypeValue;

  // Validate each row
  rows.forEach((row, index) => {
    const rowIndex = index + 2; // Because array is zero-indexed

    // RepeatType validation
    const repeatType = row[headers.indexOf("RepeatType")];
    if (!["WEEKLY", "MONTHLY", "BI_MONTHLY"].includes(repeatType)) {
      errors.push(`RepeatType column must have values between WEEKLY, MONTHLY and BI_MONTHLY in row ${rowIndex}`);
    } else {
      if (repeatTypeValue === undefined) {
        repeatTypeValue = repeatType;
      } else if (repeatTypeValue !== repeatType) {
        errors.push("RepeatType column must have same values across all rows");
      }
    }

    // WEEKLY validations
    if (repeatType === "WEEKLY") {
      const repeatDay = row[headers.indexOf("RepeatDay")];
      const repeatFrequency = row[headers.indexOf("RepeatFrequency")];

      if (repeatDay == null) {
        errors.push(`RepeatDay column shouldn't be empty in row ${rowIndex}`);
      }
      if (repeatFrequency == null) {
        errors.push(`RepeatFrequency column shouldn't be empty in row ${rowIndex}`);
      }
      if (!Number.isInteger(repeatDay)) {
        errors.push(`RepeatDay column value should be an integer in row ${rowIndex}`);
      }
      if (!Number.isInteger(repeatFrequency)) {
        errors.push(`RepeatFrequency column value should be an integer in row ${rowIndex}`);
      }
    }

    // MONTHLY validations
    if (repeatType === "MONTHLY") {
      const monthlyDate = row[headers.indexOf("MonthlyDate")];
      if (monthlyDate == null) {
        errors.push(`MonthlyDate column shouldn't be empty in row ${rowIndex}`);
      }
      if (!Number.isInteger(monthlyDate)) {
        errors.push(`MonthlyDate column value should be in integer in row ${rowIndex}`);
      }
    }

    // BI_MONTHLY validations
    if (repeatType === "BI_MONTHLY") {
      const biMonthlyDateOne = row[headers.indexOf("BiMonthlyDateOne")];
      const biMonthlyDateTwo = row[headers.indexOf("BiMonthlyDateTwo")];

      if (biMonthlyDateOne == null) {
        errors.push(`BiMonthlyDateOne column shouldn't be empty in row ${rowIndex}`);
      }
      if (biMonthlyDateTwo == null) {
        errors.push(`BiMonthlyDateTwo column shouldn't be empty in row ${rowIndex}`);
      }
      if (!Number.isInteger(biMonthlyDateOne)) {
        errors.push(`BiMonthlyDateOne column values should be in integer in row ${rowIndex}`);
      }
      if (!Number.isInteger(biMonthlyDateTwo)) {
        errors.push(`BiMonthlyDateTwo column values should be in integer in row ${rowIndex}`);
      }
    }

    // Type validation
    const type = row[headers.indexOf("Type")];
    if (!["E", "F", "O"].includes(type)) {
      errors.push(`Type column must have values between E, F, and O in row ${rowIndex}`);
    } else {
      if (type == null) {
        errors.push(`Type must have data in row ${rowIndex}`);
      }
      if (typeof type !== "string") {
        errors.push(`Type column data should be in string in row ${rowIndex}`);
      }
    }

    // HeaderRowNumber validation
    const headerRowNumber = row[headers.indexOf("HeaderRowNumber")];
    if (headerRowNumber == null) {
      errors.push(`HeaderRowNumber must have data in row ${rowIndex}`);
    }
    if (!Number.isInteger(headerRowNumber)) {
      errors.push(`HeaderRowNumber column data should be in integer in row ${rowIndex}`);
    }

    // FileNamePrefix and EmployeeIdColumnName validation
    const fileNamePrefix = row[headers.indexOf("FileNamePrefix")];
    const employeeIdColumnName = row[headers.indexOf("EmployeeIdColumnName")];
    if (!fileNamePrefix) {
      errors.push(`FileNamePrefix column must have data in row ${rowIndex}`);
    }
    if (!employeeIdColumnName) {
      errors.push(`EmployeeIdColumnName column must have data in row ${rowIndex}`);
    }
    if (typeof fileNamePrefix !== "string") {
      errors.push(`FileNamePrefix column data should be in string in row ${rowIndex}`);
    }
    if (typeof employeeIdColumnName !== "string") {
      errors.push(`EmployeeIdColumnName column data should be in string in row ${rowIndex}`);
    }
  });

  // Remove duplicate errors
  return Array.from(new Set(errors));
};

/**
 * Create payload from validated excel data
 */
export const createFileConfigPayload = (data, clientId) => {
  const headers = data[0];
  const rows = data.slice(1);

  // Initialize payload object
  const payload = {
    clientId,
    repeatType: "",
    fileConfigurations: [],
  };

  // Helper function to get value by header name
  const getValue = (row, header) => row[headers.indexOf(header)];

  rows.forEach((row) => {
    const repeatType = getValue(row, "RepeatType");

    // Set repeatType in payload if not already set
    if (!payload.repeatType) {
      payload.repeatType = repeatType;
    }

    // Add to fileConfigurations
    payload.fileConfigurations.push({
      type: getValue(row, "Type"),
      fileNamePrefix: getValue(row, "FileNamePrefix"),
      employeeIdColumn: getValue(row, "EmployeeIdColumnName"),
      headerRow: getValue(row, "HeaderRowNumber"),
    });

    // Add specific fields based on repeatType
    if (repeatType === "WEEKLY") {
      payload.repeatDay = getValue(row, "RepeatDay");
      payload.repeatFrequency = getValue(row, "RepeatFrequency");
    } else if (repeatType === "MONTHLY") {
      payload.monthlyDate = getValue(row, "MonthlyDate");
    } else if (repeatType === "BI_MONTHLY") {
      payload.biMonthlyDateOne = getValue(row, "BiMonthlyDateOne");
      payload.biMonthlyDateTwo = getValue(row, "BiMonthlyDateTwo");
    }
  });

  return payload;
};

/**
 * Validate excel data for mappings
 */
export const validateMappingsExcelData = (data) => {
  const errors = [];

  // Check if the data is empty
  if (data.length === 0) {
    errors.push("File should not be empty");
    return { errors };
  }

  // Extract header and rows
  const headers = data[0];
  const rows = data.slice(1);

  const expectedHeaders = ["InputFile", "ColumnName", "EFileColumnNumber", "NumberOfValuePairs"];

  // Check for missing headers
  expectedHeaders.forEach((header) => {
    if (!headers.includes(header)) {
      errors.push(`${header} column is missing`);
    }
  });

  // Check if there is only one row and it's the header
  if (rows.length === 0) {
    errors.push("There is no data present in uploaded file");
    return { errors };
  }

  // Validate each row
  rows.forEach((row, index) => {
    const rowIndex = index + 2; // Because array is zero-indexed

    // InputFile validation
    const inputFile = row[headers.indexOf("InputFile")];
    if (typeof inputFile !== "string") {
      errors.push(`InputFile column must have string value in row ${rowIndex}`);
    }

    // ColumnName validation
    const columnName = row[headers.indexOf("ColumnName")];
    if (typeof columnName !== "string") {
      errors.push(`ColumnName column must have string value in row ${rowIndex}`);
    }

    // EFileColumnNumber validation
    const eFileColumnNumber = row[headers.indexOf("EFileColumnNumber")];
    if (!Number.isInteger(eFileColumnNumber)) {
      errors.push(`EFileColumnNumber column must have integer value in row ${rowIndex}`);
    }

    // NumberOfValuePairs validation
    const numberOfValuePairs = row[headers.indexOf("NumberOfValuePairs")];
    if (!Number.isInteger(numberOfValuePairs) || numberOfValuePairs < 0) {
      errors.push(`NumberOfValuePairs column must have integer value >= 0 in row ${rowIndex}`);
    } else {
      for (let i = 1; i <= numberOfValuePairs; i++) {
        const clientValueColumn = `ClientValue${i}`;
        const newValueColumn = `NewValue${i}`;

        if (!headers.includes(clientValueColumn)) {
          errors.push(`${clientValueColumn} column must exist`);
        }
        if (!headers.includes(newValueColumn)) {
          errors.push(`${newValueColumn} column must exist`);
        }

        const clientValue = row[headers.indexOf(clientValueColumn)];
        const newValue = row[headers.indexOf(newValueColumn)];

        if (!headers.includes(clientValueColumn)) {
          errors.push(`${clientValueColumn} column must exist`);
        } else if (clientValue == null || clientValue === "") {
          errors.push(`${clientValueColumn} value should not be empty in row ${rowIndex}`);
        }

        if (!headers.includes(newValueColumn)) {
          errors.push(`${newValueColumn} column must exist`);
        } else if (newValue == null || newValue === "") {
          errors.push(`${newValueColumn} value should not be empty in row ${rowIndex}`);
        }
      }
    }
  });

  // Remove duplicate errors
  return Array.from(new Set(errors));
};

export const createMappingsPayload = (data, fileConfiguration, clientId) => {
  const headers = data[0];
  const rows = data.slice(1);
  const payload = [];

  rows.forEach((row) => {
    const payloadEntry = {
      fileNamePrefix: row[headers.indexOf("InputFile")],
      fileColName: row[headers.indexOf("ColumnName")],
      eFileColumnsId: row[headers.indexOf("EFileColumnNumber")],
      valueMappings: [],
    };

    for (let i = 1; i <= row[headers.indexOf("NumberOfValuePairs")]; i++) {
      payloadEntry.valueMappings.push({
        originalValue: row[headers.indexOf(`ClientValue${i}`)],
        mappedValue: row[headers.indexOf(`NewValue${i}`)],
      });
    }

    payload.push(payloadEntry);
  });

  // Add file configuration id
  let updatedPayload = payload.map((item) => {
    const fileConfig = fileConfiguration.find((config) => config.fileNamePrefix === item.fileNamePrefix);
    if (fileConfig) {
      return { ...item, fileConfigurationsId: fileConfig.id };
    }
    return item;
  });

  updatedPayload = { clientId, mappings: updatedPayload };
  return updatedPayload;
};

export const handleError = (error, dispatch = null) => {
  console.error(error);
  message.error(error.error.data.message);
  return null;
};

export const toCamelCaseToSentence = (string) => {
  return string.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
};

export const snakeCaseToSentence = (string) => {
  return string
    ?.split("_")
    ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    ?.join(" ");
};

export const hasOnlyDigits = (string) => {
  return /^-{0,1}\d+$/.test(string);
};

export const getColor = (value) => {
  //value from 0 to 1
  let hue = ((1 - value) * 120).toString(10);
  return ["hsl(", hue, ",65%,70%)"].join("");
};

export const isNumberRegex = () => {
  return new RegExp("^[0-9]*$");
};

export const isDecimalRegex = () => {
  return new RegExp("^\\d+\\.?\\d*$");
};

export const isRolePresent = (roles, userRoles) => {
  let hasRole = true;
  if (roles && roles.length > 0) {
    let roleFound = false;
    for (const routeRole of roles ?? []) {
      if (userRoles.includes(routeRole)) {
        roleFound = true;
        break;
      }
    }
    hasRole = roleFound;
  }
  return hasRole;
};

export const redirectToLogin = (error = "") => {
  if (typeof window !== "undefined") {
    let basePort = K.Network.URL.Client.BasePort === "" ? "" : ":" + K.Network.URL.Client.BasePort;
    let newUrl = window.location.protocol + "//" + K.Network.URL.Client.BaseHost + basePort + "/login";
    if (error !== "") {
      newUrl += `?err=${error}`;
    }
    window.location = newUrl;
  }
};

export const redirectToUrl = (path) => {
  window.location = window.location.protocol + "//" + K.Network.URL.Client.BaseHost + ":" + K.Network.URL.Client.BasePort + path;
};

export const setFieldErrorsFromServer = (error, form, values = undefined) => {
  if (error.error === undefined) return;
  const errors = error.error.data.errors;
  if (typeof errors === "string" || errors instanceof String) {
    return;
  }
  let fieldErrors = [];
  // debugger;
  for (let key in errors) {
    if (errors.hasOwnProperty(key)) {
      // let fieldError = errors[key].map((error) => {
      //     return error;
      // });
      fieldErrors.push({
        name: key,
        errors: errors[key],
        value: values && values[key] ? values[key] : undefined,
      });
    }
  }
  form.setFields(fieldErrors);
};

export const snakeToCamel = (str) => {
  return str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
};

export const camelCaseKeys = (obj) =>
  Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [snakeToCamel(field)]: obj[field],
    }),
    {},
  );

export const deleteQueryParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.delete(key);
  history.push({
    search: queryParams.toString(),
  });
};

export const maxLengthValidation = async (value, length, fieldName, Unit = "Characters") => {
  if (value?.length > length) return Promise.reject(new Error(`${fieldName} should be less than ${length} ${Unit}!`));
};

export const replaceWordWithBold = (str) => {
  const regex = /\(([^)]+)\)/g;
  let boldStr = str.replace(regex, "<b>($1)</b>");
  boldStr = <div dangerouslySetInnerHTML={{ __html: boldStr }} />;
  return boldStr;
};

export const convertToSlug = (str) => {
  return str?.toLowerCase()?.replace(/\s+/g, "-");
};

export const filterSelectOptions = (inputValue, option) => {
  if (Array.isArray(option?.children)) {
    return (
      String(option?.children[2])?.toLowerCase()?.indexOf(String(inputValue)?.toLowerCase()) >= 0 ||
      String(option?.value)?.toLowerCase()?.indexOf(String(inputValue)?.toLowerCase()) >= 0
    );
  }

  return (
    String(option?.children)?.toLowerCase()?.indexOf(String(inputValue)?.toLowerCase()) >= 0 ||
    String(option?.value)?.toLowerCase()?.indexOf(String(inputValue)?.toLowerCase()) >= 0
  );
};

export const noTrailingSpaceAllowedRule = () => ({
  pattern: new RegExp("^[^\\s]+(\\s+[^\\s]+)*$", "g"),
  message: "Leading and trailing spaces are not allowed.",
});

// Underscore (_) is allowed in this rule
export const noSpecialCharactersRule = () => ({
  pattern: new RegExp("^[a-zA-Z0-9_\\s]+(\\s+[a-zA-Z0-9_]+)*$", "g"),
  message: "Special characters are not allowed.",
});
